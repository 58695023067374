import {Component, OnInit} from '@angular/core';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {ApiService} from '../../shared/service/api.service';
import {ApiData} from '../../shared/model/api-data.model';
import {AppStorage, StorageKey} from '../../storage';
import {EVENT_NAMES, EventBroadcasterService} from '../../shared/service/event-broadcaster.service';
import {Router} from '@angular/router';
import {getAddressErrorMessage, getPasswordErrorMessage, Loading} from '../../utils';
import {LanguageService} from '../../shared/service/language.service';
import * as _ from 'lodash';
import {Title} from '@angular/platform-browser';
import iziToast from 'izitoast';
import {Bootbox} from '../../bootbox';
import {EnvironmentService} from '../../shared/service/environment.service';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  pageImageUrl: string;
  apiData: ApiData;

  loginUsername: string = '';
  loginPassword: string = '';

  registerName: string = '';
  registerPhone: string = '';
  registerPassword: string = '';
  registerPasswordConfirm: string = '';
  registerCity: string = '';
  registerDistrict: string = '';
  registerStreet: string = '' ;
  registerPostcode: string = '';
  registerAddress: string = '';
  registerTosAccepted: boolean = true;
  registerPosAccepted: boolean = true;

  forgotPasswordUsername: string = '';

  postCode: string;
  country: string;
  _street: any;
  _cities: any;
  _districts: any;

  private locale: string = "TR";

  constructor(private router: Router,
              private apiService: ApiService,
              private eventBroadcasterService: EventBroadcasterService,
              private languageService: LanguageService,
              private titleService: Title,
              public environmentService: EnvironmentService) {
  }

  ngOnInit() {
    const that = this;

    $('.modal').on('shown.bs.modal', function() {
      $(this).before($('.modal-backdrop'));
    });

    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiData = apiData;
      this.country = this.apiData.Country;

      const translations = this.languageService.translations;
      this.titleService.setTitle(`${this.apiData.Title} | ${translations['signup.signin']}`);


      if (this.apiData.Country !== 'GBR') {
        Loading.show()
          .then(() => this.apiService.getCities(this.apiData.Settings.Locale))
          .then(data => {
            that._cities = data;
          })
          .catch(error => console.error(error))
          .finally(() => {
            Loading.hide();
          });
      }
      else{
        this.registerCity = '';
        this.registerDistrict = '';
      }
    });

    $(function() {
      const login = $('#login');
      const signup = $('#signup');
      const login_page = $('#login-page');
      const signup_page = $('#signup-page');

      login.click(() => {
        login_page.removeClass('uk-hidden');

        signup_page.addClass('uk-hidden');

        login.addClass('active active-bottom');
        login.removeClass('passive-bottom');

        signup.addClass('passive-bottom');
        signup.removeClass('active active-bottom');
      });

      signup.click(() => {
        login_page.addClass('uk-hidden');
        signup_page.removeClass('uk-hidden');

        login.addClass('passive-bottom');
        login.removeClass('active active-bottom');

        signup.addClass('active active-bottom');
        signup.removeClass('passive-bottom');
      });
    });
  }

  onClickPostCodeButton(){
    const translations = this.languageService.translations;
    Loading.show().then(()=>{
      this.postCode = this.registerPostcode;
      if (this.postCode !== "" && (this.environmentService.environment.Locale === 'SA' || this.environmentService.environment.Locale === 'DE' || this.environmentService.environment.Locale === 'GB' || this.environmentService.environment.Locale === 'TR' )) {

        this.apiService.getAdress('GB', this.postCode).then(data => {
          this._street = data;

          if (this._street.length <= 0) {
            Bootbox.alert(translations['signup.postcode_validation_error_c']);
            return;
          }
          else {
            this.registerCity = this._street[0].Name;
            this.registerDistrict = this._street[0].District;
          }
        })
      }
    })
      .finally(() => Loading.hide());
  }

  onClickLoginButton() {
    const translations = this.languageService.translations;

    if (_.isEmpty(this.loginUsername) || _.isEmpty(this.loginPassword)) {
      Bootbox.alert(translations['signup.user_name_password_error_c']);
      return;
    }

    let userBundle = {
      CustomerID: ApiService.customerId,
      Username: this.loginUsername.replace(/\D/g, ''),
      Password: this.loginPassword,
    };

    Loading.show()
      .then(() => this.apiService.loginUser(userBundle))
      .then(data => {
        // Save user details
        AppStorage.setItem(StorageKey.USER, data);

        // Send a broadcast to all listeners
        this.eventBroadcasterService.trigger(EVENT_NAMES.OnLoggedIn, {});

        // Redirect user to index page
        this.router.navigate([''])
          .then();
      })
      .catch(() => {
        Bootbox.alert(translations['signup.user_name_password_error_c']);
      })
      .finally(() => Loading.hide());
  }

  onClickRegisterButton() {
    const translations = this.languageService.translations;

    //region Validation
    if (_.isEmpty(this.registerName)) {
      Bootbox.alert(translations['general.name_error_c']);
      return;
    }

    if (this.apiData.Country === 'GBR') {
      if (_.isEmpty(this.registerStreet)) {
        Bootbox.alert(translations['general.street_error_c']);
        return;
      }
    }


    const addressBundle = {
      telephone: this.registerPhone,
      address: this.registerAddress,
      city: this.registerCity,
      district: this.registerDistrict,
      street: this.registerStreet,
      postcode: this.registerPostcode
    };

    const addressError = getAddressErrorMessage(
      addressBundle,
      translations
    );

    if (addressError != null) {
      Bootbox.alert(addressError);
      return;
    }

    const passwordError = getPasswordErrorMessage(
      this.registerPassword,
      translations
    );

    if (passwordError != null) {
      Bootbox.alert(passwordError);
      return;
    }

    if (this.registerTosAccepted !== true) {
      Bootbox.alert(translations['general.terms_of_use_should_be_accepted']);
      return;
    }

    if (this.registerPosAccepted !== true) {
      Bootbox.alert(translations['general.terms_of_privacy_should_be_accepted']);
      return;
    }
    //endregion
    const account = {
      CustomerID: ApiService.customerId,
      Name: this.registerName,
      Cellphone: this.registerPhone,
      Telephone: this.registerPhone,
      Phone: this.registerPhone,
      ImageUrl: null,
      CityTown: this.registerCity,
      District: this.registerDistrict,
      Street: this.registerStreet,
      Address: this.registerAddress,
      UserName: this.registerPhone,
      Password: this.registerPassword,
      PostCode: this.registerPostcode
    };

    Loading.show()
      .then(() => {
        if (this.country !== 'GBR' && (this.apiData.Country === 'SA' || this.apiData.Country === 'DE' || this.apiData.Country === 'GB' || this.apiData.Country === 'TR' ))
          return this.apiService.checkPostcode(this.apiData.Settings.Locale, this.registerCity, this.registerDistrict, this.registerPostcode)
      })
      .then(data => {
        if (data === 0)
          throw 0;
      })
      .then(() => this.apiService.createAccount(account))
      .then((data) => {
        if (!data.hasOwnProperty('ID')) return;

        return this.apiService.getAccount(data['ID'])
      })
      .then((data) => {
        AppStorage.setItem(StorageKey.USER, data);

        Bootbox.alert({
          message: translations['signup.user_registered_successfully_c'],
          callback: () => {
            this.eventBroadcasterService.trigger(EVENT_NAMES.OnLoggedIn, {});

            this.router.navigate([''])
              .then();
          }
        })
      })
      .catch((error) => {
        if (error.hasOwnProperty('responseJSON') && error['responseJSON'] === "Account is already registered!") {
          Bootbox.alert(translations['signup.guest_is_already_registered_c']);
          return;
        }

        if (error === 0) {
          Bootbox.alert(translations['signup.postcode_validation_error_c']);
          return;
        }

        Bootbox.alert(translations['signup.signup_error_c']);
      })
      .finally(() => {
        Loading.hide();
      })
  }

  onSelectedCityChanged () {
    this.apiService.getDistricts(this.apiData.Settings.Locale, this.registerCity)
      .then(data => {
        this.registerDistrict = "-1";
        this._districts = data;
      });
  }

  onSelectedStreetChanged(value) {
    this.registerStreet = value;
  }

  onClickSendPassword() {
    Loading.show()
      .then(() => this.apiService.getAccountPassword(this.forgotPasswordUsername))
      .then(() => {
        const translations = this.languageService.translations;

        iziToast.success({
          position: 'topRight',
          title: translations['general.success'],
          message: translations['signup.password_sms_send']
        });

        // Close modal
        // @ts-ignore
        $('#forgotPasswordModal').modal('hide');
      })
      .catch((error) => {
        const translations = this.languageService.translations;
        let message = translations['general.unexpected_error_occured'];

        if (error.hasOwnProperty('responseJSON') && error.responseJSON === "Account is not found!") {
          message = translations['signup.phone_number_not_found_c'];
        }

        iziToast.error({
          position: 'topRight',
          title: translations['general.error'],
          message
        });

      })
      .finally(() => {
        Loading.hide();
      });
  }

}

