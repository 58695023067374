export enum WebsiteImageCategory {
    Slider = 0,
    Homepage = 1,
    Menu = 2,
    Gallery = 3,
    Reservation = 4,
    Footer = 9,
    Contact = 10,
}

export enum GalleryImageCategory {
    Any = 0,
}

export enum SignageImageCategory {
    Slide = 0,
    Video = 1,
    Simple = 2,
    Departure = 3,
    Modern = 4,
    Circles = 5,
    Gallery = 6,
    Mobile = 7,
    Blackboard = 8,
    BlackView = 9,
}

export enum LogoImageCategory {
    Default = 0,
    BlackText = 1,
    WhiteText = 2,
    Footer = 3,
}

export enum CustomerDisplayImageCategory {
    Any = 0,
}
