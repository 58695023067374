import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiImage} from '../../shared/model/api-image.model';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {SignageImageCategory} from '../../shared/enum/image-category.enum';
import {ApiDataService} from '../../shared/service/api-data.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  images: ApiImage[];

  constructor(private apiService: ApiService, private apiDataService: ApiDataService) {
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.images = this.apiDataService.getImages(SignageImageCategory.Slide);
    });
  }

}
