import {ApiImage} from './shared/model/api-image.model';
import {IAlbum} from 'ngx-lightbox';

export function convertToAlbum(image: ApiImage): IAlbum {
  let src = image.ImageUrl;
  const caption = image.Description;
  const thumb = null;

  return {src, caption, thumb};
}

export function createAlbum(images: ApiImage[]): IAlbum[] {
  return images.map(value => convertToAlbum(value));
}

export function getImageUrl(value: { ImageUrl: string } | undefined): string | undefined {
  return value && value.ImageUrl;
}

export function setMissingImageUrls(set: { ImageUrl?: string }[], fallbackImageUrl: string) {
  for (const i of set) {
    if (!i.ImageUrl) {
      i.ImageUrl = fallbackImageUrl;
    }
  }
}
