import {Component, OnInit} from '@angular/core';
import {initAirport} from 'src/app/signage/departure/plugin/js/jquery.airport.js';
import {ApiService} from '../../shared/service/api.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {getImageUrl} from '../../api-utils';
import {SignageImageCategory} from '../../shared/enum/image-category.enum';
import {ApiDataService} from '../../shared/service/api-data.service';

@Component({
  selector: 'app-departure',
  templateUrl: './departure.component.html',
  styleUrls: ['./departure.component.scss']
})
export class DepartureComponent implements OnInit {
  footerMessage = 'Afiyet olsun';
  pageImageUrl: string;

  constructor(private apiService: ApiService, private apiDataService: ApiDataService) {
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.pageImageUrl = getImageUrl(this.apiDataService.getSignagePageImage(SignageImageCategory.Departure)) ||
        '/assets/images/page_backgrounds/departure.jpg';

      const products = this.apiDataService.getSignageProducts();

      initAirport(products);
      const oclock = $('#oclock');
      (oclock as any).oclock();

      const divFlap = $('#divFlap');
      (divFlap as any).initAirport();

      // Angular tarafından her elemana eklenen ortak özellik herhangi bir eleman üzerinden elde edilir.
      const html = oclock[0].outerHTML;
      const attr = html.substr(0, html.indexOf(' ', html.indexOf(' ') + 1)).split(' ')[1].slice(0, -3);

      // Stil sınıflarının uygulanabilmesi için ortak Angular özelliği jQuery'nin oluşturduğu elemanların tüm alt elemanlara uygulanır.
      oclock.find('*').attr(attr, '');
      divFlap.find('*').attr(attr, '');
    });
  }

}
