export enum OrderStatus {
  NEW = 0,
  ACCEPTED = 1,
  PREPARED = 2,
  SERVED = 3,
  PAID = 4,
  INVOICED = 5,
  TRANSFERED = 6,
  NOT_ACCEPTED = -1,
  WAITING = -2,
  TIMEOUT = -3,
  CANCELLED = -4,
}
