import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {LanguageService} from '../../shared/service/language.service';
import {Title} from '@angular/platform-browser';
import {EnvironmentService} from '../../shared/service/environment.service';
import {Loading} from '../../utils';

@Component({
  selector: 'policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss'],
})
export class PoliciesComponent implements OnInit {
  appName: string = '';
  contactEmail: string = '';
  mersisNo: string = '';

  constructor(private apiService: ApiService,
              private languageService: LanguageService,
              private titleService: Title,
              public environmentService: EnvironmentService) {
  }

  ngOnInit() {
    function dene(){

    }
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      const translations = this.languageService.translations;

      this.appName = apiData.Title;
      this.contactEmail = apiData.Email;
      this.mersisNo = '';

      // Set page title
      this.titleService.setTitle(`${apiData.Title} | ${translations['policies.title']}`);

      // TODO: Remove this as soon as possible!
      Loading.show()
        .then(() => this.apiService.getCustomerSummary())
        .then(data => {
          this.mersisNo = data['MersisNo'];
        })
        .then(() => {
          Loading.hide();
        });
    });
  }
}
