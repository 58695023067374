import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {ApiData} from '../../model/api-data.model';
import {ApiFunctionName} from '../../enum/api-function-name.enum';
import {LogoImageCategory} from '../../enum/image-category.enum';
import {getImageUrl} from '../../../api-utils';
import {ApiDataService} from '../../service/api-data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LanguageService} from '../../service/language.service';
import {EVENT_NAMES, EventBroadcasterService} from '../../service/event-broadcaster.service';
import {CartService} from '../../service/cart.service';
import {ApiAccount} from '../../model/api.account.model';
import {getLoggedInUser, isLoggedIn} from '../../../utils';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbModal]
})

export class HeaderComponent implements OnInit {
  apiData: ApiData;
  user: ApiAccount;
  logoUrl: string;
  curlangs: string;
  langs: string;
  basketCount: number = 0;
  currentLanguage: string;
  languages: string[];
  isLoggedIn: boolean = false;
  public currentRoute: string;
  public isMobile: { Android: () => RegExpMatchArray; BlackBerry: () => RegExpMatchArray; iOS: () => RegExpMatchArray; Opera: () => RegExpMatchArray; Windows: () => RegExpMatchArray; any: () => RegExpMatchArray };
  onlineOrderDisable: boolean;

  constructor(private eventBroadcasterService: EventBroadcasterService,
              private languageService: LanguageService,
              private apiService: ApiService,
              private apiDataService: ApiDataService,
              private cartService: CartService,
              private router: Router) {

    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;
        const bg = document.getElementById('hamburger-menu');
        if (this.currentRoute !== '/' && this.currentRoute !== '') {
          bg.style.display = 'none';
        } else {
          bg.style.display = 'flex';
        }
      });
  }

  ngOnInit() {
    this.isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini|Chrome|Mozilla|Edge/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function() {
        return (this.isMobile.Android() || this.isMobile.BlackBerry() || this.isMobile.iOS() || this.isMobile.Opera() || this.isMobile.Windows());
      }
    };

    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.onlineOrderDisable = apiData.Settings.WebSiteOrderMode !== 0;
      this.logoUrl = getImageUrl(this.apiDataService.getLogo(LogoImageCategory.BlackText));

      this.apiData = apiData;

      this.languages = this.languageService.languages;

      this.currentLanguage = this.languageService.currentLanguage;

      this.curlangs = 'assets/images/flags/1x1/' + this.currentLanguage.toLowerCase() + '.svg';
      this.langs = 'assets/images/flags/1x1/' + this.languages + '.svg';
    });

    // Register a listener
    this.eventBroadcasterService.get(EVENT_NAMES.OnLoggedIn).subscribe(() => {
      this.checkUser();
    });

    // Get cart item count
    this.basketCount = this.cartService.cart.length;

    // Also check for updates
    this.cartService.watch(() => {
      this.basketCount = this.cartService.cart.length;
    });

    this.checkUser();
  }

  closeNav() {
    document.getElementById('headerNav').style.marginLeft = '-100vw';
    document.getElementById('bg-left-menu').style.display = 'none';
  }

  openNav() {
    document.getElementById('headerNav').style.marginLeft = '0';
    document.getElementById('bg-left-menu').style.display = 'flex';
  }

  onClickChangeLanguage(language: string) {
    this.currentLanguage = this.languageService.updateLanguage = language;
    window.location.reload();
  }

  checkUser() {
    this.user = getLoggedInUser();
    this.isLoggedIn = isLoggedIn();
  }
}
