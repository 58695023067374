import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {ApiData} from '../../model/api-data.model';
import {ApiFunctionName} from '../../enum/api-function-name.enum';
import {getImageUrl} from '../../../api-utils';
import {LogoImageCategory, WebsiteImageCategory} from '../../enum/image-category.enum';
import {ApiDataService} from '../../service/api-data.service';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js';
import {isLoggedIn} from '../../../utils';
import {EnvironmentService} from '../../service/environment.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  pageImageUrl: string;
  apiData: ApiData;
  footerLogoUrl: string;

  telephoneURI: string;

  isLoggedIn: boolean = false;
  isMobileAppLinksExist: boolean;
  onlineOrderDisable: boolean;

  constructor(private apiService: ApiService,
              private apiDataService: ApiDataService,
              public environmentService: EnvironmentService) {
  }

  ngOnInit() {
    $('.modal').on('shown.bs.modal', function() {
      $(this).before($('.modal-backdrop'));
    });

    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.onlineOrderDisable = apiData.Settings.WebSiteOrderMode !== 0;

      this.pageImageUrl = getImageUrl(this.apiDataService.getPageImage(WebsiteImageCategory.Footer));

      this.footerLogoUrl = getImageUrl(this.apiDataService.getLogo(LogoImageCategory.Footer));

      this.apiData = apiData;

      this.telephoneURI = `tel:${this.apiData.Telephone}`;

      this.isLoggedIn = isLoggedIn();

      if (apiData.Website.AppStoreUrl == null) {
        apiData.Website.AppStoreUrl = '';
      }

      if (apiData.Website.GooglePlayUrl == null) {
        apiData.Website.GooglePlayUrl = '';
      }

      this.isMobileAppLinksExist = apiData.Website.AppStoreUrl.length > 0 || apiData.Website.GooglePlayUrl.length > 0;

      try {
        const parsedPhone = parsePhoneNumber(
          this.apiData.Telephone,
          this.environmentService.environment.Locale as CountryCode
        );

        this.telephoneURI = parsedPhone.getURI();
        this.apiData.Telephone = parsedPhone.formatNational();

      } catch (e) {

      }
    });
  }

}
