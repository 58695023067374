import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './index/index.component';
import {BasketComponent} from './basket/basket.component';
import {ProductDetailsComponent} from './product-details/product-details.component';
import {SignupComponent} from './signup/signup.component';
import {WebsiteRoutingModule} from './website-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {MainPipe} from '../shared/pipes/main.pipe';
import {FormsModule} from '@angular/forms';
import {ProfileDetailsComponent} from './profile-details/profile-details.component';
import {TranslateModule} from '@ngx-translate/core';
import {AboutComponent} from './about/about.component';
import {PoliciesComponent} from './policies/policies.component';
import {PoliciesTRComponent} from './policies/policies.tr/policies.tr.component';
import {PoliciesUKComponent} from './policies/policies.uk/policies.uk.component';

@NgModule({
  declarations: [
    IndexComponent,
    BasketComponent,
    ProductDetailsComponent,
    ProfileDetailsComponent,
    SignupComponent,
    AboutComponent,
    PoliciesComponent,
    PoliciesTRComponent,
    PoliciesUKComponent,
  ],
  imports: [
    CommonModule,
    WebsiteRoutingModule,
    HttpClientModule,
    MainPipe,
    FormsModule,
    TranslateModule
  ]
})
export class WebsiteModule {
}
