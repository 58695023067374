import {Component} from '@angular/core';
import {PoliciesComponent} from '../policies.component';
import {BasketComponent} from '../../basket/basket.component';

@Component({
  selector: 'policies-tr',
  templateUrl: './policies.tr.component.html',
  styleUrls: ['../policies.component.scss'],
})
export class PoliciesTRComponent extends BasketComponent {}
