import {Injectable} from '@angular/core';
import {Environment} from '../model/environment.model';

@Injectable()
export class EnvironmentService {
  private static environment: Environment;

  constructor() {
    EnvironmentService.environment = {
      CustomerID: -1,
      Locale: 'TR',
      Currency: '₺',
    };
  }

  set environment(value: Environment) {
    EnvironmentService.environment = value;
  }

  get environment(): Environment {
    return EnvironmentService.environment;
  }
}
