import {TRANSLATION_KEYS, TranslationLoaderService} from './translations.service';
import {ApiLanguage} from '../model/api.language.model';
import {TranslateService} from '@ngx-translate/core';
import {AppStorage, StorageKey} from '../../storage';
import * as _ from 'lodash';

export class LanguageService {
  readonly validLanguageCodes = [
    TRANSLATION_KEYS.TR,
    TRANSLATION_KEYS.EN,
    TRANSLATION_KEYS.DE
  ];

  // Customer languages
  private readonly _languages: string[];

  // Default language
  private _defaultLanguage: string;

  private _translate: TranslateService;

  // An array that contains all translations
  private _translations: object;

  constructor() {
    this._languages = [];
    this._defaultLanguage = TRANSLATION_KEYS.TR;
  }

  /**
   * Adds language to languages
   * @param language
   */
  addLanguage(language: ApiLanguage) {
    // Check validity of language code
    if (!this.validLanguageCodes.find(i => i === language.Code)) {
      return;
    }

    if (language.Default) {
      this._defaultLanguage = language.Code;
      this.translate.setDefaultLang(language.Code);
    }

    this._languages.push(language.Code);
  }

  checkSetLanguage() {
    // Check if any language code data
    // is present in Local Storage
    this.currentLanguage = AppStorage.getItem(StorageKey.LANGUAGE_CODE);

    // If current language is null then set default language
    if (this.currentLanguage === null) {
      this.currentLanguage = this.defaultLanguage;
      return;
    }
  }

  initTranslations(lang: string) {
    this._translations = TranslationLoaderService.translations[lang];
  }

  set translate(translate: TranslateService) {
    window['translate'] = this._translate = translate;
  }

  get translate() {
    return this._translate;
  }

  /**
   * Gets languages of customer
   */
  get languages() {
    // If no languages are added as valid
    // then return Turkish by default
    if (this._languages.length === 0) {
      return [TRANSLATION_KEYS.TR];
    }

    return this._languages;
  }

  /**
   * Gets default language of customer
   */
  get defaultLanguage() {
    return this._defaultLanguage;
  }

  /**
   * Sets selected language and applies
   * changes.
   * @param language
   */
  set currentLanguage(language: string) {
    if (!this.languages.find(i => i === language)) {
      if (this.languages.length === 1) {
        language = _.first(this.languages);
      } else {
        language = TRANSLATION_KEYS.TR;
      }
    }

    AppStorage.setItem(StorageKey.LANGUAGE_CODE, language);

    this.translate.use(language);

    this.initTranslations(language);
  }

  /**
   * Sets selected language and does not apply
   * changes.
   * @param language
   */
  set updateLanguage(language) {
    if (!this.languages.find(i => i === language)) {
      language = TRANSLATION_KEYS.TR;
    }

    AppStorage.setItem(StorageKey.LANGUAGE_CODE, language);

    this.initTranslations(language);
  }

  /**
   * Gets selected language
   */
  get currentLanguage() {
    let currentLanguage = AppStorage.getItem(StorageKey.LANGUAGE_CODE);

    if (currentLanguage === null) {
      return this.defaultLanguage;
    }

    return currentLanguage;
  }

  get translations() {
    return this._translations;
  }
}
