import {Renderer2} from '@angular/core';

export class ModuleUtils {
  private static readonly signageClasses = [
    'unselectable',
    'no-overflow'
  ];

  static addSignageClasses(renderer: Renderer2) {
    for (const className of this.signageClasses) {
      renderer.addClass(document.body, className);
    }
  }

  static removeSignageClasses(renderer: Renderer2) {
    for (const className of this.signageClasses) {
      renderer.removeClass(document.body, className);
    }
  }
}
