import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ApiGuestCall} from '../../shared/model/api-guest-call.model';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';

@Component({
  selector: 'app-waiter-display',
  templateUrl: './waiter-display.component.html',
  styleUrls: ['./waiter-display.component.scss']
})
export class WaiterDisplayComponent implements OnInit, OnDestroy {
  private static readonly NUM_COUNT = 3;
  private static readonly REFRESH_INTERVAL = 3000;
  private static readonly NOTIFICATION_SOUND_SRC = 'assets/sounds/definite.mp3';
  tables: ApiGuestCall [] = [];
  private subscription: Subscription;
  private url: string;
  private audio: HTMLAudioElement = new Audio(WaiterDisplayComponent.NOTIFICATION_SOUND_SRC);

  constructor(private apiService: ApiService, private http: HttpClient) {
    for (let i = 0; i < WaiterDisplayComponent.NUM_COUNT; i++) {
      this.tables.push({TableName: '', TableNumber: '--', CallTime: 0} as any);
    }
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.url = `${ApiService.API_BASE_URL}/GuestCallAPI/GetLastCalls?customerID=${apiData.CustomerID}&count=${WaiterDisplayComponent.NUM_COUNT}`;

      this.setNumbers();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setNumbers() {
    this.subscription = this.http.get(this.url)
      .subscribe(value => {
        let numEmptySlots = WaiterDisplayComponent.NUM_COUNT;

        if (value instanceof Array) {
          const length = Math.min(WaiterDisplayComponent.NUM_COUNT, value.length);
          numEmptySlots = WaiterDisplayComponent.NUM_COUNT - length;
          let hasNewCall = false;

          for (let i = 0; i < length; i++) {
            const call = value[i];

            if (Date.parse(call.CallTime) > Date.parse(this.tables[i].CallTime)) {
              hasNewCall = true;
            }

            this.tables[i] = call;
            this.tables[i].TableNumber = isNaN(call.TableNumber)
              ? '--' as any
              : call.TableNumber.toLocaleString('en-US', {minimumIntegerDigits: 2});
          }

          if (hasNewCall) {
            this.audio.play().catch(console.error);
          }
        }

        for (let i = 0; i < numEmptySlots; i++) {
          this.tables[i].TableNumber = '--' as any;
        }

        setTimeout(() => this.setNumbers(), WaiterDisplayComponent.REFRESH_INTERVAL);
      });
  }

}
