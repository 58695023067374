import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CirclesComponent} from './circles/circles.component';
import {SignageRoutingModule} from './signage-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {MainPipe} from '../shared/pipes/main.pipe';
import {DepartureComponent} from './departure/departure.component';
import {BlackboardComponent} from './blackboard/blackboard.component';
import {SimpleComponent} from './simple/simple.component';
import {MobileComponent} from './mobile/mobile.component';
import {ModernComponent} from './modern/modern.component';
import {SliderComponent} from './slider/slider.component';
import {WaiterDisplayComponent} from './waiter-display/waiter-display.component';

@NgModule({
  declarations: [
    CirclesComponent,
    DepartureComponent,
    BlackboardComponent,
    SimpleComponent,
    MobileComponent,
    ModernComponent,
    SliderComponent,
    WaiterDisplayComponent
  ],
  imports: [
    CommonModule,
    SignageRoutingModule,
    HttpClientModule,
    MainPipe
  ]
})
export class SignageModule {
}
