import {Injectable} from '@angular/core';
import {ApiOrder} from '../model/api.order.model';
import {CartService} from './cart.service';
import {OrderStatus} from '../enum/order-status.enum';
import {PaymentType} from '../enum/payment-type.enum';
import {AppStorage, StorageKey} from '../../storage';
import {ApiService} from './api.service';
import {DeliveryType} from '../enum/delivery-type.enum';

@Injectable()
export class OrderService {
  private _order: ApiOrder;
  private _orderKeys: any;

  constructor(private _apiService: ApiService, private _cartService: CartService) {
    this._order = undefined;
    this._orderKeys = Object.freeze(Object.keys(new ApiOrder()));
  }

  get order () {
    return this._order;
  }

  _createOrder(options: OrderParams) : ApiOrder {
    // Check options if null
    if (options == null) {
      options = new OrderParams();
    }

    let params = new OrderParams();
    params = Object.assign(params, options);

    const paymentType = options.paymentType;

    // TODO: Fix service price
    const servicePrice = 0;

    params.paymentType = paymentType;
    params.servicePrice = servicePrice;

    /*
    if (isPaymentRequired(params.paymentType)) {
      params.status = AppEnums.OrderStatus.WAITING;
    }
    */

    const order = Object.assign(this._cartService.getOrder(), {
      CustomerID: ApiService.customerId,
      AccountID: params.accountId,
      Status: params.orderStatus,
      Service: params.servicePrice,
      Type: 1,
      OrderType: options.orderType,
      Paid: params.paid,
      PaymentType: params.paymentType,
      DeliveryType: params.deliveryType,
      Address: params.address,
      Description: params.description || "",
      UniqueID: params.uniqueId,
      Source: "Web Store",
    });

    order.TotalPrice += order.Service;

    // If order does not have an account ID then
    // set account ID of the order to user's account ID
    if (order.AccountID === null) {
      const user = AppStorage.getItem(StorageKey.USER);
      order.AccountID = user.AccountID;
    }

    return order;
  }

  _onOrderSent() {
    // this._cartService.clear();
  }

  /**
   * Sets current order and
   * its account ID
   * @param options
   */
  setOrder(options) {
    return new Promise(resolve => {
      this._order = this._createOrder(options);
      resolve()
    });
  }

  /**
   * Posts the order
   */
  postOrder() {
    return new Promise((resolve, reject) => {
      return this._apiService.postOrder(this._order)
        .then(result => {
          this._onOrderSent();

          resolve(result);
        })
        .catch(error => reject(error));
    })
  }

  /**
   * Puts the order
   */
  putOrder() {
    return new Promise((resolve, reject) => {
      return this._apiService.putOrder(this._order)
        .then(() => {
          this._onOrderSent();

          resolve();
        })
        .catch(error => reject(error));
    })
  }
}

export class OrderParams {
  accountId: number;
  address: string;
  servicePrice: number;
  uniqueId: number;
  description: string;

  orderStatus: number;
  orderType: number;
  paid: boolean;
  paymentType: number;
  deliveryType: number;

  constructor() {
    this.accountId = null;

    this.orderStatus =  OrderStatus.NEW;
    this.orderType = 18;  // Web Store
    this.paid = false;
    this.paymentType =  PaymentType.Cash;
    this.deliveryType = DeliveryType.Delivery;
  }
}
