import {TranslateLoader} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import * as _ from 'lodash';

export const TRANSLATION_KEYS = Object.freeze({
  TR: 'TR',
  EN: 'EN',
  DE: 'DE'
});

export const TRANSLATIONS = Object.freeze({
  about: {
    title: {
      TR: 'Hakkımızda',
      EN: 'About Us',
      DE: 'Über Uns'
    }
  },
  policies: {
    title: {
      TR: 'Şartlar & Koşullar',
      EN: 'Terms & Conditions',
      DE: 'Geschäftsbedingungen'
    }
  },
  header: {
    login: {
      TR: 'Giriş Yap',
      EN: 'Login',
      DE: 'Anmelden'
    },
    cart: {
      TR: 'Sepetim',
      EN: 'My Basket',
      DE: 'Einkaufswagen'
    },
    menus: {
      TR: 'Menüler',
      EN: 'Menus',
      DE: 'Menüs'
    },
    stand: {
      TR: 'Reyon',
      EN: 'Stand',
      DE: 'Viskose'
    },
    main: {
      TR: 'Anasayfa',
      EN: 'Home',
      DE: 'Startseite'
    }
  },
  index: {
    product: {
      select_quantity: {
        TR: 'Lütfen adet seçin',
        EN: 'Please select quantity',
        DE: 'Bitte Menge auswählen'
      },
      select_addtocart: {
        TR: 'Sepete Ekle',
        EN: 'Add To Basket',
        DE: 'In den Einkaufswagen'
      },
      review_product: {
        TR: 'Ürünü İncele',
        EN: 'Review Product',
        DE: 'Product überprüfen'
      },
    },
    gallery: {
      title: {
        TR: 'Bizden Kareler',
        EN: 'Pictures',
        DE: 'Unsere Bilder'
      }
    },
    rate_us: {
      title: {
        TR: 'Bizi Oylayın',
        EN: 'Rate Us',
        DE: 'Bewerten Sie Uns'
      },
      comment: {
        TR: 'Görüşleriniz bizim için önemli, siparişlerinizi puanlandırmayı unutmayın!',
        EN: 'Your feedback is important to us, don\'t forget to rate your orders!',
        DE: 'Ihr Feedback ist uns wichtig. Vergessen Sie nicht, Ihre Bestellungen zu bewerten!'
      },
      send: {
        TR: 'Gönder',
        EN: 'Send',
        DE: 'Senden'
      },
      menu: {
        TR: 'Menü',
        EN: 'Menu',
        DE: 'Menü'
      },
      price: {
        TR: 'Fiyat',
        EN: 'Price',
        DE: 'Preis'
      },
      service: {
        TR: 'Servis',
        EN: 'Service',
        DE: 'Bedienung'
      },
      kindness: {
        TR: 'Nezaket',
        EN: 'Kindness',
        DE: 'Freundlichkeit'
      },
      environment: {
        TR: 'Ortam',
        EN: 'Enviroment',
        DE: 'Umgebung'
      }
    },
  },
  basket: {
    pay_3d: {
      TR: '3D Secure ile ödemek istiyorum.',
      EN: 'I want to pay with 3D Secure.',
      DE: 'Ich möchte mit 3D Secure bezahlen.'
    },
    something_went_wrong: {
      TR: 'HATA!',
      EN: 'ERROR!',
      DE: 'FEHLER!'
    },
    order_fail_message: {
      TR: 'Restoranın açık olduğu gün veya saat aralığında değilsiniz.',
      EN: 'You are not on the day or time the restaurant is open.',
      DE: 'Sie sind nicht an dem Tag oder der Uhrzeit, an dem das Restaurant geöffnet ist.'
    },
    close_uc: {
      TR: 'Kapat',
      EN: 'Close',
      DE: 'Schließen'
    },
    title: {
      TR: 'Sepetim',
      EN: 'My Basket',
      DE: 'Einkaufswagen'
    },
    product: {
      name: {
        TR: 'Ürün',
        EN: 'Product',
        DE: 'Produkt'
      },
      quantity: {
        TR: 'Miktar',
        EN: 'Quantity',
        DE: 'Menge'
      },
      price: {
        TR: 'Fiyat',
        EN: 'Price',
        DE: 'Preis'
      },
    },
    distancesellingagreementtext: {
      TR: 'Mesafeli satış sözleşmesi\'ni',
      EN: 'Distance selling contract',
      DE: 'Fernabsatzvertrag'
    },
    distancesellingagreementtext2: {
      TR: 'okudum ve onaylıyorum.',
      EN: 'read and confirm.',
      DE: 'Ich habe gelesen, dass ich zustimme'
    },
    distancesellingagreementtext3: {
      TR: 'Mesafeli satış sözleşmesini okumak için tıklayın.',
      EN: 'Click to read the distance sales contract.',
      DE: 'Klicken Sie hier, um den Fernabsatzvertrag zu lesen.'
    },
    order_successful: {
      TR: 'Siparişiniz başarıyla tamamlandı.',
      EN: 'Your order has been successfully completed.',
      DE: 'Ihre Bestellung wurde erfolgreich abgeschlossen.'
    },
    return_homepage: {
      TR: 'Anasayfaya Dön',
      EN: 'Return to Homepage',
      DE: 'Zur Startzeite Zurückkehren'
    },
    order_note: {
      TR: 'Sipariş notunuzu giriniz',
      EN: 'Enter your order note',
      DE: 'Geben Sie Ihren Bestellschein ein'
    },
    cart_sum: {
      TR: 'Sipariş Özeti',
      EN: 'Order summary',
      DE: 'Bestellübersicht'
    },
    clear_cart: {
      TR: 'Temizle',
      EN: 'Clear',
      DE: 'Löschen'
    },
    select: {
      TR: 'Seçiniz',
      EN: 'Select',
      DE: 'Auswählen'
    },
    address: {
      TR: 'Adres',
      EN: 'Address',
      DE: 'Adresse'
    },
    edit: {
      TR: 'Düzenle',
      EN: 'Edit',
      DE: 'Bearbeiten'
    },
    enter_address: {
      TR: 'Adresinizi giriniz',
      EN: 'Enter your address',
      DE: 'Geben Sie Ihre Address ein'
    },
    city: {
      TR: 'Şehir',
      EN: 'City',
      DE: 'Stadt'
    },
    district: {
      TR: 'İlçe',
      EN: 'District',
      DE: 'Bezirk'
    },
    street: {
      TR: 'Mahalle',
      EN: 'Street',
      DE: 'Nachbarschaft'
    },
    postcode: {
      TR: 'Posta Kodu',
      EN: 'Postcode',
      DE: 'Postleitzahl'
    },
    enter_postcode: {
      TR: 'Posta kodunu giriniz',
      EN: 'Enter your post code information',
      DE: 'Geben Sie die Postleitzahl ein'
    },
    phone: {
      TR: 'Telefon',
      EN: 'Phone',
      DE: 'Telefon'
    },
    save_changes: {
      TR: 'Değişiklikleri Kaydet',
      EN: 'Save Changes',
      DE: 'Änderungen Speichern'
    },
    select_paymentmethod: {
      TR: 'Lütfen ödeme yöntemini seçiniz',
      EN: 'Please select your payment method',
      DE: 'Wählen Sie eine Bezahlart'
    },
    payment_awaiting: {
      TR: 'Ödeme bekleniyor...',
      EN: 'Payment awaiting...',
      DE: 'Zahlung erwartet...'
    },
    payment_error: {
      TR: 'Ödeme sırasında hata oluştu. Lütfen tekrar deneyin.',
      EN: 'An error occurred during payment. Please try again.',
      DE: 'Während der Zahlung ist ein Fehler aufgetreten. Bitte versuche es erneut.'
    },
    cash: {
      TR: 'Nakit',
      EN: 'Cash',
      DE: 'Kasse'
    },
    transfer: {
      TR: 'EFT-Havale',
      EN: 'EFT-Transfer',
      DE: 'EFT-Überweisen'
    },
    creditcard: {
      TR: 'Kredi Kartı',
      EN: 'Credit Card',
      DE: 'Kreditkarte'
    },
    enter_cardinfo: {
      TR: 'Kart bilgilerinizi giriniz',
      EN: 'Enter your card information',
      DE: 'Geben Sie Ihre Karteninformationen'
    },
    total: {
      TR: 'Toplam',
      EN: 'Total',
      DE: 'Gesamt'
    },
    card: {
      name: {
        TR: 'Kart Sahibinin Adı Soyadı',
        EN: 'Cardholder Name and Surname',
        DE: 'Vor und Nachname des Karteninhabers'
      },
      number: {
        TR: 'Kart Numarası',
        EN: 'Card Number',
        DE: 'Kartennummer'
      },
      expiration_date: {
        TR: 'Geçerlilik Tarihi',
        EN: 'Expiration Date',
        DE: 'Ende der Gültigkeit'
      },
      cvc: {
        TR: 'CVC',
        EN: 'CVC',
        DE: 'CVC'
      }
    },
    remember_card: {
      TR: 'Bu Kartı Hatırla',
      EN: 'Remember This Card',
      DE: 'Erinnere diese Karte'
    },
    pay: {
      TR: 'Öde',
      EN: 'Pay',
      DE: 'Zahlung'
    },
    complete_order: {
      TR: 'Siparişi Tamamla',
      EN: 'Complete Order',
      DE: 'Bestellung abschließen'
    },
    payment_awaiting_refresh: {
      TR: 'Yenile',
      EN: 'Refresh',
      DE: 'Aktualisierung'
    },
    go_to_orders: {
      TR: 'Siparişlere Git',
      EN: 'Go to orders',
      DE: 'Gehen sie zu bestellungen'
    },
    cart_empty: {
      TR: 'Sepetiniz boş!',
      EN: 'Your cart is empty!',
      DE: 'Ihr Warenkorb ist leer.'
    },
    delivery_type: {
      TR: 'Lütfen teslimat türünü seçiniz',
      EN: 'Please select delivery type',
      DE: 'Bitte wählen sie lieferart'
    },
    home_delivery: {
      TR: 'Adrese Teslim',
      EN: 'Home Delivery',
      DE: 'Hauslieferung'
    },
    come_get: {
      TR: 'Gel Al',
      EN: 'Collection',
      DE: 'Komm Und Hol'
    },
    online_order: {
      TR: 'Online Ödeme',
      EN: 'Online Payment',
      DE: 'Onlinebezahlung'
    },
    paypal: {
      TR: 'Paypal',
      EN: 'Paypal',
      DE: 'Paypal'
    },
    restaurant_unavailable: {
      TR: 'Bu işletme şu anda sipariş alamamaktadır.',
      EN: 'Restaurant is currently unable to take orders.',
      DE: 'Das Restaurant kann derzeit keine Bestellungen entgegennehmen.'
    },
    restaurant_not_serve_postcode: {
      EN: 'Sorry, this restaurant does not serve to your area.',
      TR: 'Bu işletme sizin bölgenize hizmet vermemektedir.',
      DE: 'Entschuldigung, dieses Restaurant ist nicht in Ihrer Nähe.'
    },
    find: {
      EN: 'Find',
      TR: 'Bul',
      DE: 'Finden.'
    },
    cartempty: {
      EN: 'Your Shopping Cart is Empty',
      TR: 'Alışveriş Sepetiniz Boş',
      DE: 'Dein Einkaufswagen ist leer.'
    },
    discoverlatestproduct: {
      EN: 'Discover the latest products now',
      TR: 'En yeni ürünleri şimdi keşfedin',
      DE: 'Entdecken Sie jetzt die neusten Produkte'
    },
    discoverproducts: {
      EN: 'Discover Products',
      TR: 'Ürünleri Keşfet',
      DE: 'Entdecken Sie Produkte'
    },
    loginplease: {
      EN: 'Login',
      TR: 'Giriş Yapınız',
      DE: 'Anmeldung'
    },
    logintocompleteorder: {
      EN: 'Please login to complete your order.',
      TR: 'Siparişinizi tamamlamak için lütfen giriş yapınız.',
      DE: 'Bitte melden Sie sich an, um Ihre Bestellung abzuschließen.'
    },
    plogin: {
      EN: 'Login',
      TR: 'Giriş Yap',
      DE: 'Anmeldung'
    },
  },
  product_details: {
    feature_quantity_short_error: {
      EN: 'You have to select at least {{amount}} option(s) on {{name}}',
      TR: '{{ name }} kategorisinde en az {{ amount }} seçenek seçmelisiniz.',
      DE: 'Sie müssen mindestens {{amount}} Option(en) auf {{name}} auswählen'
    },
    feature_quantity_long_error: {
      EN: 'You can select maximum {{amount}} options on {{name}}',
      TR: '{{name}} kategorisinde en fazla {{amount}} seçenek seçebilirsiniz.',
      DE: 'Sie können maximal {{amount}} Optionen für {{name}} auswählen'
    },
    select_quantity: {
      TR: 'Lütfen adet seçin',
      EN: 'Please select quantity',
      DE: 'Bitte Menge auswählen'
    },
    select_addtocart: {
      TR: 'Sepete Ekle',
      EN: 'Add To Basket',
      DE: 'In den Einkaufswagen'
    }
  },
  profile_details: {
    title: {
      TR: 'Hesabım',
      EN: 'My Account',
      DE: 'Mein Konto'
    },
    right_menu: {
      account_info: {
        TR: 'Hesap Bilgileri',
        EN: 'Account Information',
        DE: 'Konto Information'
      },
      orders: {
        TR: 'Siparişlerim',
        EN: 'My Orders',
        DE: 'Meine Bestellungen'
      },
      saved_cards: {
        TR: 'Kayıtlı Kartlar',
        EN: 'Saved Cards',
        DE: 'Gespeicherte Karten'
      },
      change_password: {
        TR: 'Parolayı Değiştir',
        EN: 'Change Password',
        DE: 'Passwort ändern'
      },
      logout: {
        TR: 'Çıkış Yap',
        EN: 'Logout',
        DE: 'Abmelden'
      },
    },
    account_info: {
      save_changes: {
        TR: 'Değişiklikleri Kaydet',
        EN: 'Save Changes',
        DE: 'Änderungen Speichern'
      },
      select: {
        TR: 'Seçiniz',
        EN: 'Select',
        DE: 'Auswählen'
      },
      name: {
        TR: 'Ad',
        EN: 'Name',
        DE: 'Name'
      },
      surname: {
        TR: 'Soyad',
        EN: 'Surname',
        DE: 'Nachname'
      },
      address: {
        TR: 'Adres',
        EN: 'Address',
        DE: 'Address'
      },
      phone: {
        TR: 'Telefon',
        EN: 'Phone',
        DE: 'Telefon'
      },
      email: {
        TR: 'E-posta',
        EN: 'Email',
        DE: 'Email'
      },
      city: {
        TR: 'Şehir',
        EN: 'City',
        DE: 'Stadt'
      },
      district: {
        TR: 'İlçe',
        EN: 'District',
        DE: 'Bezirk'
      },
      street: {
        TR: 'Mahalle',
        EN: 'Street',
        DE: 'Nachbarschaft'
      },
      postcode: {
        TR: 'Posta Kodu',
        EN: 'Postcode',
        DE: 'Postleitzahl'
      },
      success_profile_update: {
        TR: 'Profiliniz başarıyla güncellendi.',
        EN: 'Your profile is successfully updated.',
        DE: 'Ihr Profil wurde erfolgreich aktualisiert.'
      },
      profile_update_error_c: {
        TR: 'Profil güncelleme aşamasında sorun oluştu.',
        EN: 'Problem occurred during profile update.',
        DE: 'Während der Profilaktualisierung ist ein Problem aufgetreten.'
      },
      postcode_validation_error_c: {
        TR: 'Girilen posta kodu doğrulanamadı. Lütfen kontrol edip tekrar deneyin.',
        EN: 'The given postcode couldn\'t be verified. Please check and try again.',
        DE: 'Die angegebene Postleitzahl konnte nicht überprüft werden. Bitte überprüfen Sie es und versuchen Sie es erneut.'
      },
    },
    my_orders: {
      cart_sum: {
        TR: 'Sipariş Özeti',
        EN: 'Order summary',
        DE: 'Bestellübersicht'
      },
      order_status: {
        TR: 'Sipariş Durumu',
        EN: 'Order Status',
        DE: 'Bestellstatus'
      },
      order_delivered: {
        TR: 'Teslim Edildi',
        EN: 'Delivered',
        DE: 'Wurde Geliefert'
      },
      order_cancelled: {
        TR: 'İptal Edildi',
        EN: 'Cancelled',
        DE: 'Abgesagt'
      },
      on_delivery: {
        TR: 'Yolda',
        EN: 'On Delivery',
        DE: 'Bei Lieferung'
      },
      orders: {
        TR: 'Siparişler',
        EN: 'Orders',
        DE: 'Bestellungen'
      },
      active_orders: {
        TR: 'Aktif Siparişler',
        EN: 'Active Orders',
        DE: 'Active Bestellungen'
      },
      inactive_orders: {
        TR: 'Eski Siparişler',
        EN: 'Previous Orders',
        DE: 'Alten Bestellugen'
      },
      total: {
        TR: 'Toplam',
        EN: 'Total',
        DE: 'Gesamt'
      },
      order_status_new: {
        TR: 'Yeni',
        EN: 'New',
        DE: 'Neu'
      },
      order_status_accepted: {
        TR: 'Kabul Edildi',
        EN: 'Accepted',
        DE: 'Akzeptiert'
      },
      order_status_prepared: {
        TR: 'Hazırlandı',
        EN: 'Prepared',
        DE: 'Bereit'
      },
      order_status_served: {
        TR: 'Servis Edildi',
        EN: 'Served',
        DE: 'Serviert'
      },
      order_status_paid: {
        TR: 'Ödendi',
        EN: 'Paid',
        DE: 'Bezahlt'
      },
      order_status_invoiced: {
        TR: 'Faturalandı',
        EN: 'Invoiced',
        DE: 'Rechnung'
      },
      order_status_transfered: {
        TR: 'Transfer Edildi',
        EN: 'Transferred',
        DE: 'Übertragen'
      },
      order_status_not_accepted: {
        TR: 'Reddedildi',
        EN: 'Rejected',
        DE: 'Abgelehnt'
      },
      order_status_waiting: {
        TR: 'Bekliyor',
        EN: 'Waiting',
        DE: 'Warten'
      },
      order_status_timeout: {
        TR: 'Onaylanmadı',
        EN: 'Not accepted',
        DE: 'Nicht akzeptiert'
      },
      order_status_cancelled: {
        TR: 'İptal edildi',
        EN: 'Cancelled',
        DE: 'Stornieren'
      },
      payment_type_cash: {
        TR: 'Nakit',
        EN: 'Cash',
        DE: 'Kasse'
      },
      payment_type_cc: {
        TR: 'Kredi Kartı',
        EN: 'Credit Card',
        DE: 'Kreditkarten'
      },
      payment_type_online: {
        TR: 'Online Ödeme',
        EN: 'Online',
        DE: 'Online'
      }
    },
    my_cards: {
      edit: {
        TR: 'Düzenle',
        EN: 'Edit',
        DE: 'Bearbeiten'
      },
      saved_cards: {
        TR: 'Kayıtlı Kartlar',
        EN: 'Saved Cards',
        DE: 'Gespeicherte Karten'
      },
      new_card_add: {
        TR: 'Yeni Kart Ekle',
        EN: 'Add New Card',
        DE: 'Neue Karte Hinzufügen'
      },
      enter_cardinfo: {
        TR: 'Kart bilgilerinizi giriniz',
        EN: 'Enter your card information',
        DE: 'Geben Sie Ihre Karteninformationen'
      },
      card: {
        name: {
          TR: 'Kart Sahibi',
          EN: 'Card Owner',
          DE: 'Karteninhaber'
        },
        number: {
          TR: 'Kart Numarası',
          EN: 'Card Number',
          DE: 'Kartennummer'
        },
        expiration_date: {
          TR: 'Geçerlilik Tarihi',
          EN: 'Expiration Date',
          DE: 'Ende der Gültigkeit'
        },
        cvc: {
          TR: 'CVC',
          EN: 'CVC',
          DE: 'CVC'
        }
      },
    },
    change_password: {
      old_password: {
        TR: 'Eski Parola',
        EN: 'Old Password',
        DE: 'Altes Passwort'
      },
      new_password: {
        TR: 'Yeni Parola',
        EN: 'New Password',
        DE: 'Neues Passwort'
      },
      confirm_password: {
        TR: 'Parola Doğrula',
        EN: 'Confirm Password',
        DE: 'Passwort bestätigen'
      },
      passwords_not_match: {
        TR: 'Parolalar eşlemiyor.',
        EN: 'Password do not match.',
        DE: 'Passwörter stimmen nicht überein.'
      },
      password_updated: {
        TR: 'Parola başarıyla güncellendi.',
        EN: 'Password is successfully updated.',
        DE: 'Passwort wurde erfolgreich aktualisiert.'
      }
    },
    save_changes: {
      TR: 'Değişiklikleri Kaydet',
      EN: 'Save Changes',
      DE: 'Änderungen Speichern'
    },
  },
  signup: {
    signup: {
      TR: 'Kayıt Ol',
      EN: 'Sign Up',
      DE: 'Registrieren'
    },
    signin: {
      TR: 'Giriş Yap',
      EN: 'Sign In',
      DE: 'Anmelden'
    },
    or: {
      TR: 'ya da',
      EN: 'or',
      DE: 'oder'
    },
    continue_as_guest: {
      TR: 'Misafir olarak devam et',
      EN: 'Continue as a guest',
      DE: 'Als Gast fortfahlen'
    },
    select: {
      TR: 'Seçiniz',
      EN: 'Select',
      DE: 'Auswählen'
    },
    name_surname: {
      TR: 'Ad & Soyad',
      EN: 'Name & Surname',
      DE: 'Name & Nachname'
    },
    phone: {
      TR: 'Telefon',
      EN: 'Phone',
      DE: 'Telefon'
    },
    address: {
      TR: 'Adres',
      EN: 'Address',
      DE: 'Address'
    },
    city: {
      TR: 'Şehir',
      EN: 'City',
      DE: 'Stadt'
    },
    district: {
      TR: 'İlçe',
      EN: 'District',
      DE: 'Bezirk'
    },
    street: {
      TR: 'Mahalle',
      EN: 'Street',
      DE: 'Nachbarschaft'
    },
    postcode: {
      TR: 'Posta Kodu',
      EN: 'Postcode',
      DE: 'Postleitzahl'
    },
    remember_me: {
      TR: 'Beni Hatırla',
      EN: 'Remember Me',
      DE: 'Angemeldet bleiben'
    },
    forgot_password: {
      TR: 'Parolamı Unuttum',
      EN: 'Forgot Password?',
      DE: 'Passwort vergessen?'
    },
    password: {
      TR: 'Parola',
      EN: 'Password',
      DE: 'Passwort'
    },
    confirm_password: {
      TR: 'Parola Doğrula',
      EN: 'Confirm Password',
      DE: 'Passwort bestätigen'
    },
    terms_of_privacy_approval: {
      TR: 'Gizlilik sözleşmesini okudum ve onaylıyorum.',
      EN: 'I have read and agree the terms of privacy.',
      DE: 'Ich habe die Datenschutzbestimmungen gelesen und bin damit einverstanden.'
    },
    terms_of_use_approval: {
      TR: 'Kullanım koşullarını okudum ve onaylıyorum.',
      EN: 'I have read and agree the terms of use.',
      DE: 'Ich habe die Nutzungsbedingungen gelesen und erkläre mich einverstanden.'
    },
    send: {
      TR: 'Gönder',
      EN: 'Send',
      DE: 'Senden'
    },
    user_name_password_error_c: {
      TR: 'Hatalı kullanıcı adı veya parola yanlış. Lütfen tekrar deneyin.',
      EN: 'Username or password is incorrect. Please try again.',
      DE: 'Benutzername oder Passwort is falsch. Bitte versuchen Sie es noch einmal.'
    },
    postcode_validation_error_c: {
      TR: 'Girilen posta kodu doğrulanamadı. Lütfen kontrol edip tekrar deneyin.',
      EN: 'The given postcode couldn\'t be verified. Please check and try again.',
      DE: 'Die angegebene Postleitzahl konnte nicht überprüft werden. Bitte überprüfen Sie es und versuchen Sie es erneut.'
    },
    signup_error_c: {
      TR: 'Kayıt esnasında sorun oluştu',
      EN: 'There was a problem during registration',
      DE: 'Bei der Registrierung ist ein Problem aufgetreten'
    },
    guest_is_already_registered_c: {
      TR: 'Bu telefon numarası ile daha önce kayıt olunmuş.',
      EN: 'This telephone number is already registered.',
      DE: 'Diese Telefonnummer ist bereits registriert.'
    },
    user_registered_successfully_c: {
      TR: 'Başarıyla kayıt oldunuz. Tamam tuşuna basarak devam edin.',
      EN: 'You have successfully registered. Click OK to continue.',
      DE: 'Sie sich erfolgreich registriert haben. Klicken Sie auf OK, um fortzufahren.'
    },
    password_sms_send: {
      TR: 'Parolanız SMS ile gönderilmiştir.',
      EN: 'Your password has been sent via sms.',
      DE: 'Ihr Passwort wurde als SMS gesendet.'
    },
    phone_number_not_found_c: {
      TR: 'Telefon numarası bulunamadı.',
      EN: 'Phone number is not found.',
      DE: 'Telefonnummer wurde nicht gefunden.'
    },
  },
  footer: {
    aboutus: {
      TR: 'Hakkımızda',
      EN: 'About Us',
      DE: 'Über Uns'
    },
    workingdays: {
      TR: 'Çalışma Günleri',
      EN: 'Working Days',
      DE: 'Arbeitstage'
    },
    home: {
      TR: 'Ana Sayfa',
      EN: 'Home',
      DE: 'Startseite'
    },
    login: {
      TR: 'Giriş Yap',
      EN: 'Login',
      DE: 'Anmelden'
    },
    profile_detail: {
      TR: 'Hesabım',
      EN: 'My Account',
      DE: 'Meine Konto'
    },
    cart: {
      TR: 'Sepetim',
      EN: 'My Basket',
      DE: 'Einkaufswagen'
    },
    termsofuse: {
      TR: 'Kullanım Koşulları',
      EN: 'Terms of Use',
      DE: 'Nutzungsbedingungen'
    },
    privacypolicy: {
      TR: 'Gizlilik Politikası',
      EN: 'Privacy Policy',
      DE: 'Datenschutz-Bestimmungen'
    },
    cancellationrefundpolicy: {
      TR: 'İptal & İade Koşulları',
      EN: 'Cancellation & Refund Policy',
      DE: 'Stornierungs- und Rückerstattungsrichtlinie'
    },
    kvkktext: {
      TR: 'KVKK Metni',
      EN: 'KVKK Text',
      DE: 'KVKK-Text'
    },
    distancesellingagreement: {
      TR: 'Mesafeli Satış Sözleşmesi',
      EN: 'Distance Selling Agreement',
      DE: 'Fernabsatzvertrag'
    },
    privacyanduse: {
      TR: 'Kullanım ve Gizlilik',
      EN: 'Use and Privacy',
      DE: 'Nutzung und Datenschutz'
    },
    mobileapps: {
      TR: 'Mobil Uygulamalar',
      EN: 'Mobile Apps',
      DE: 'Mobil Apps'
    },
    rightsreserved: {
      TR: 'Tüm hakkı saklıdır',
      EN: 'All rights reserved',
      DE: 'Alle rechte vorbehalten'
    },
    supportline: {
      TR: 'Müşteri Destek Hattı',
      EN: 'Customer Support Line',
      DE: 'Kunden Support Hotline'
    },
    socialmedias: {
      TR: 'Sosyal Medya Hesapları',
      EN: 'Social Media Accounts',
      DE: 'Konten für soziale Medien'
    },
  },
  general: {
    city_error_c: {
      TR: 'Lütfen şehrinizi seçin',
      EN: 'Please select your city',
      DE: 'Bitte wählen Sie Ihren Stadt aus'
    },
    district_error_c: {
      TR: 'Lütfen ilçenizi seçiniz',
      EN: 'Please select your district',
      DE: 'Bitte wählen Sie Ihren Landkreis aus'
    },
    postcode_error_c: {
      TR: 'Posta kodunuzu giriniz',
      EN: 'Enter your postcode',
      DE: 'Geben Sie Ihre Postleitzahl ein'
    },
    address_error_c: {
      TR: 'Adresinizi giriniz',
      EN: 'Enter your address',
      DE: 'Geben Sie Ihre Adresse ein'
    },
    telephone_invalid: {
      TR: 'Telefon geçersiz. Lütfen en az 9 rakam girin.',
      EN: 'The telephone is invalid. Please enter at least 9 digits.',
      DE: 'Das Telefon ist ungültig. Bitte geben Sie mindestens 9 Ziffern ein.',
    },
    error_phone_cannot_be_empty: {
      TR: 'Telefon alanı boş olamaz.',
      EN: 'Phone field can not be empty.',
      DE: 'Das Telefonfeld darf nicht leer sein.',
    },
    name_error_c: {
      TR: 'Ad soyad giriniz.',
      EN: 'Enter your name and surname.',
      DE: 'Geben Sie Ihren Vollständigen Namen ein.'
    },
    street_error_c: {
      TR: 'Lütfen girdiğiniz posta koduna göre bir sokak seçiniz.',
      EN: 'Please select a street based on the postal code you entered.',
      DE: 'Bitte wählen Sie anhand der eingegebenen Postleitzahl eine Straße aus.'
    },
    password_error_invalid: {
      TR: 'Parola geçersiz.',
      EN: 'Password is invalid.',
      DE: 'Passwort ist ungültig.'
    },
    password_error_too_short: {
      TR: 'Parola en az 6 karakter uzunluğunda olmalıdır.',
      EN: 'Password must be at least 6 characters long.',
      DE: 'Das Passwort muss mindestens 6 Zeichen lang sein.'
    },
    error_password_cannot_be_empty: {
      TR: 'Parola alanı boş olamaz.',
      EN: 'Password field can not be empty.',
      DE: 'Das Passwort darf nicht leer sein.',
    },
    terms_of_privacy_should_be_accepted: {
      TR: 'Gizlilik sözleşmesi onaylanmaldır.',
      EN: 'Terms of privacy should be accepted.',
      DE: 'Datenschutzbestimmungen sollten akzeptiert werden.'
    },
    terms_of_use_should_be_accepted: {
      TR: 'Kullanım koşulları onaylanmalıdır.',
      EN: 'Terms of use should be accepted.',
      DE: 'Nutzungsbedingungen sollten akzeptiert werden.'
    },
    error: {
      TR: 'Hata',
      EN: 'Error',
      DE: 'Error',
    },
    success: {
      TR: 'Başarılı',
      EN: 'Successful',
      DE: 'Erfolgreich',
    },
    item_added_to_cart: {
      TR: 'Ürün sepetinize başarıyla eklendi.',
      EN: 'Product is successfully added to your basket.',
      DE: 'Produkt wurde erfolgreich in den Warenkorb gelegt.',
    },
    cart_cant_add_diff_menu: {
      EN: 'You cannot add products from different menus to your cart.',
      TR: 'Sepetinize farklı menülerden ürün ekleyemezsiniz.',
      DE: 'Sie können Ihrem Warenkorb keine Produkte aus verschiedenen Menüs hinzufügen.'
    },
    unexpected_error_occured: {
      TR: 'Beklenmedik bir hata ile karşılaşıldı.',
      EN: 'An unexpected error was encountered.',
      DE: 'Ein unerwarteter Fehler ist aufgetreten.',
    }
  }
});

export class TranslationLoaderService implements TranslateLoader {
  static translations: object[] = [];

  constructor() {
    for (let a of Object.keys(TRANSLATION_KEYS)) {
      const language = TRANSLATION_KEYS[a];
      TranslationLoaderService.translations[language] = [];
    }

    // Let's create our translations in a very convinient way.
    (function rec(parent: object, lastPath = '') {
      for (let a in parent) {
        if (!parent.hasOwnProperty(a)) {
          continue;
        }

        const elem = parent[a];

        if (typeof elem === 'object') {
          let newPath = `${lastPath}.${a}`;
          if (newPath.indexOf('.') === 0) {
            newPath = newPath.substring(1);
          }

          rec(elem, newPath);
        } else {
          TranslationLoaderService.translations[a][lastPath] = elem;
        }
      }
    })(TRANSLATIONS);
  }

  getTranslation(lang: string): Observable<any> {
    return of(TranslationLoaderService.translations[lang]);
  }
}

export function replaceParams(template, params) {
  if (!_.isString(template)) {
    return undefined;
  }

  if (!_.isObject(params)) {
    return undefined;
  }

  const delims = template.match(/{{([^}]+)}}/g);
  if (delims.length < 1) {
    return undefined;
  }

  let result = template;

  for (let i = 0; i < delims.length; i++) {
    const key = delims[i].replace(/\s+/g, '').substr(2).slice(0, -2);
    const value = params[key];

    if (value == null) {
      return undefined;
    }

    result = result.replace(delims[i], value);
  }

  return result;
}
