import {ApiOrderItem} from './api.order-item.model';
import {ApiAccount} from './api.account.model';
import {OrderStatus} from '../enum/order-status.enum';

export class ApiOrder {
  OrderID: number;
  OnlineOrderID: number;
  CustomerID: number;
  TableID: number;
  MenuID: number;
  SessionLID: number;
  AccountID: number;
  StaffID: number;
  UniqueID: number;
  OrderTime: string;
  OrderTimeFormatted: string;
  ClosedTime: string;
  UUID: string;
  Name: string;
  TableName: string;
  AccountName: string;
  GuestImage: string;
  ImageUrl: string;
  Price: number;
  Service: number;
  Discount: number;
  TaxPrice: number;
  TotalPrice: number;
  Status: number;
  Type: number;
  DeliveryType: number;
  OrderType: number;
  PaymentType: number;
  Reason: number;
  Transfer: number;
  Paid: boolean;
  Cancelled: boolean;
  Closed: boolean;
  Deleted: boolean;
  Description: string;
  Source: string;

  OrderItems: ApiOrderItem[];

  constructor() {
    this.AccountID = null;

    this.Status = OrderStatus.NEW;

    this.Price = 0;
    this.TotalPrice = 0;
    this.TaxPrice = 0;

    this.OrderType = 18; // Web Store

    this.Paid = false;

    // Create an empty array of order items
    this.OrderItems = [];
  }

}
