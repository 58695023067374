import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {ApiOrderItem} from '../model/api.order-item.model';
import {ApiOrder} from '../model/api.order.model';
import {ApiOrderItemFeature} from '../model/api.order-item-feature.model';
import {isLoggedIn} from '../../utils';
import {AppStorage, StorageKey} from '../../storage';

@Injectable()
export class CartService {
  private _watchListeners = [];

  private _cart: Array<ApiOrderItem> = [];
  private _currency = "";

  // Total order item quantity count in cart.
  private _totalQuantity = 0;

  // Total price
  private _cartTotal = 0;

  // Service price
  private _servicePrice = 0;

  // Menu ID
  private _menuID = -1;

  constructor() {
    // If there is no logged in user then delete cart.
    if (!isLoggedIn()) {
      AppStorage.removeItem(StorageKey.CART);
      return;
    }

    const __cart = AppStorage.getItem(StorageKey.CART);

    if (__cart != null) {
      // After restoring cart information from local storage
      // order items lose their identity. we need to
      // recreate order items.
      for(let orderItem of __cart) {
        this.cart.push(new ApiOrderItem(orderItem));
        this._cartTotal += orderItem.TotalPrice;
      }

      // Extract MenuID from cart items
      if (this._cart.length > 0) {
        const tempItem = _.head(this._cart);
        this._menuID = tempItem.MenuID;
        this._currency = tempItem.Currency;
      }
    }
  }

  get menuID() {
    return this._menuID;
  }

  /**
   * Return cart array.
   * @returns {array}
   */
  get cart() {
    return this._cart;
  }

  /**
   * Gets currency.
   * @returns {string}
   */
  get currency() {
    return this._currency;
  }

  get cartTotal() {
    return this._cartTotal;
  }

  /**
   * Gets total price of the cart.
   * This includes the service price as well.
   * Total discount is substracted
   * @return {number}
   */
  get totalPrice() {
    return this.price; //- this.totalDiscount;
  }

  /**
   * Gets cart total and service price
   * @returns {*}
   */
  get price () {
    return this._cartTotal + this._servicePrice;
  }

  /**
   * Triggers listeners that are registered
   * with watch.
   * @private
   */
  private _triggerWatchListeners() {
    for (const listener of this._watchListeners) {
      listener();
    }
  }

  /**
   * Finds items which have the same
   * order ID or product ID with orderItem
   * @param orderItem
   * @returns {boolean|array}
   * @private
   */
  private _findItems(orderItem: ApiOrderItem) {
    const id = orderItem.OrderID || orderItem.ProductID;
    if (id == null) {
      return [];
    }

    return this._cart.filter(item => {
      const itemId = item.OrderID || item.ProductID;
      if (itemId == null) {
        return false;
      }

      return itemId === id;
    });
  }

  /**
   * Finds item which are equal
   * with orderItem in any way.
   * @param orderItem
   * @returns {object|null}
   * @private
   */
  private _findSameOrderItem(orderItem: ApiOrderItem) {
    const foundItems = this._findItems(orderItem);

    if (foundItems.length <= 0) {
      return null;
    }

    for (const foundItem of foundItems) {
      if (orderItem.areFeaturesEqual(foundItem)) {
        return foundItem;
      }
    }
  }

  /**
   * Updates cart.
   * @private
   * @returns {boolean} Returns true if cart is updated.
   */
  private _updateCart() {
    const that = this;

    // Reset total quantity and total price.
    this._totalQuantity = 0;
    this._cartTotal = 0;

    for (let cartItem of this._cart) {
      that._totalQuantity += (cartItem.Quantity / cartItem.FeatureValue);
      that._cartTotal += cartItem.TotalPrice;
    }

    // Save cart details to local storage
    AppStorage.setItem(StorageKey.CART, this._cart);

    return true;
  }

  /**
   * Commit changes
   * @param orderItem
   * @private
   */
  private _commitChanges(orderItem: ApiOrderItem) {
    // Reset prices.
    orderItem.Price = orderItem.UnitPrice;
    orderItem.TotalPrice = orderItem.UnitPrice;

    orderItem.FeaturePrice = 0;

    if (!_.isEmpty(orderItem.OrderItemFeatures)) {
      this._setFeaturePrices(orderItem);
    } else {
      orderItem.Price *= orderItem.Quantity;
      orderItem.TotalPrice *= orderItem.Quantity;
    }

    // Here, calculate tax price
    orderItem.TaxPrice = Math.round(orderItem.TotalPrice * 100 * (1 - 100 / (100 + orderItem.Tax))) / 100;

    this._triggerWatchListeners();
  }

  /**
   * Set feature prices correctly.
   * @param orderItem
   * @private
   */
  private _setFeaturePrices(orderItem: ApiOrderItem) {

    let countableMultiplier = 1;
    let uncountableTotal = 0;

    orderItem.OrderItemFeatures.map((feature: ApiOrderItemFeature) => {
      if (feature.Countable) {
        countableMultiplier *= feature.Value;
      } else {
        uncountableTotal += feature.Price;
      }
    });

    orderItem.Quantity = orderItem.Quantity * countableMultiplier;

    orderItem.FeaturePrice = uncountableTotal * orderItem.Quantity;

    // We need this for further calculations.
    orderItem.FeatureValue = countableMultiplier;

    orderItem.Price = orderItem.UnitPrice * orderItem.Quantity;

    orderItem.TotalPrice = orderItem.Price + orderItem.FeaturePrice;
  }

  /**
   * Checks if an item can be added to the
   * cart or not
   * @param orderItem
   */
  canAddProduct(orderItem: ApiOrderItem) {
    // Before we begin let's check if is there
    // any item in the cart.
    if (this._cart.length === 0) {
      this._menuID = orderItem.MenuID;
      this._currency = orderItem.Currency;
    }

    if (this._menuID !== orderItem.MenuID) {
      return false;
    }

    // Check if the menu ID is valid.
    return !(this._menuID === -1 || this._menuID === null);
  }

  /**
   * Adds new order item to the cart. If same
   * order item with same features is found
   * then it increases its quantity instead adding
   * new one.
   * @param orderItem
   */
  add (orderItem: ApiOrderItem) {
    if (!this.canAddProduct(orderItem)) {
      return false;
    }

    // Now let's find an order item
    // which is same with `orderItem` and has
    // same features.
    const foundItem = this._findSameOrderItem(orderItem);

    if (foundItem) {
      // If a match is found then increase its
      // quantity and total price.
      let multiplier = 1;
      if (orderItem.FeatureValue && orderItem.FeatureValue > 0) {
        multiplier = orderItem.FeatureValue;
      }

      foundItem.Quantity += orderItem.Quantity * multiplier;

      if (orderItem.FeaturePrice && orderItem.FeaturePrice > 0) {
        foundItem.FeaturePrice = orderItem.FeaturePrice * foundItem.Quantity;
      }

      foundItem.Price = orderItem.UnitPrice * foundItem.Quantity;
      foundItem.TotalPrice = foundItem.FeaturePrice + foundItem.Price;

      this._triggerWatchListeners()

    } else {
      // Push new item to the cart.
      this._cart.push(orderItem);
      this._commitChanges(orderItem);
    }

    return this._updateCart();
  }

  /**
   * Creates an order with order items in cart.
   * @return {*}
   */
  getOrder() : ApiOrder {
    const cart = this._cart;
    if (cart.length <= 0) {
      return null;
    }

    const order = new ApiOrder();

    order.MenuID = this.menuID;

    // Get copy of order items on cart.
    const orderItems = [...cart];

    orderItems.forEach((orderItem: ApiOrderItem) => {
      const totalPrice = orderItem.TotalPrice;
      order.Price += totalPrice;
      order.TotalPrice += totalPrice;

      // Remove countable features as they already
      // affect quantity. Also confuses POS.
      orderItem.removeCountableFeatures();

      order.OrderItems.push(orderItem);

      order.TaxPrice += orderItem.TaxPrice;
    });

    order.OrderItems.map((orderItem) => {
      delete orderItem['ProductFeatureGroup'];
    });

    return order;
  }

  /**
   * Clears cart.
   */
  clear() {
    this._cart = [];
    this._updateCart();

    this._triggerWatchListeners();
  }

  /**
   * Increases order item count in cart by 1
   * @param orderItem
   */
  increment(orderItem: ApiOrderItem) {
    if (orderItem.FeatureValue && orderItem.FeatureValue > 0) {
      orderItem.Quantity /= orderItem.FeatureValue;
    }

    orderItem.Quantity += 1;

    this._commitChanges(orderItem);

    this._updateCart();
  }

  /**
   * Decreases order item count in cart by 1
   * @param orderItem
   */
  decrement(orderItem: ApiOrderItem) {
    const featureValue = orderItem.FeatureValue;

    if (featureValue && featureValue > 0) {
      orderItem.Quantity /= featureValue;
    }

    // If product count reaches minimum quantity
    // then do not decrease anymore.
    if (orderItem.Quantity === 1) {
      if (featureValue) {
        orderItem.Quantity = featureValue;
      }
      return;
    }

    orderItem.Quantity -= 1;

    // Remove item from the cart
    // if its quantity decreased to zero
    if (orderItem.Quantity === 0) {
      this.drop(orderItem);
      return;
    }

    this._commitChanges(orderItem);

    this._updateCart();
  }

  /**
   * Removes an order item from cart.
   * @param orderItem
   */
  drop(orderItem: ApiOrderItem) {
    const item = this._findSameOrderItem(orderItem);

    // Remove item from cart.
    _.pull(this._cart, item);

    this._updateCart();

    this._triggerWatchListeners();
  }

  /**
   * Adds listener to watch listeners.
   * @param listener
   * @returns {Function}
   */
  watch(listener) {
    if (!_.isFunction(listener)) {
      console.log("ERROR: Given listener is not a function.");
      return;
    }

    const listeners = this._watchListeners;

    this._watchListeners.push(listener);

    return () => {
      listeners.splice(listeners.indexOf(listener, 1));
    };
  }
}
