export enum PaymentType {
  Cash = 0,
  CreditCard = 10,

  Online = 70,
  Iyzico = 71,
  Payu = 72,
  PosPratik = 73,
  PosPratikTest = 74,
  IyzicoTest = 75,
  CardStream = 76,
  Stripe = 77,

  Transfer = 79,
}
