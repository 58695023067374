import {ApiProduct} from '../../shared/model/api-product.model';

/**
 * Eski website projesinden alınmıştır.
 */
export function getGrid(products: ApiProduct[], numPanels: number, numColumns: number, numRows: number): ApiProduct[][][] {
  // Get products from local storage
  const dataLength = numColumns * numRows * numPanels;
  const grid = [];

  let _panel = -1;
  let _column = -1;
  let _row = 0;

  for (let i = 0; i < dataLength; i++) {
    // Her 16 elementte bir yeni bir numPanels oluştur.
    if (i % (numColumns * numRows) === 0) {
      grid[++_panel] = [];
      _column = -1;
    }

    // Her 4 elementte bir o panele yeni bir sütun oluştur.
    if (i % (numRows) === 0) {
      grid[_panel][++_column] = [];
      _row = 0;
    }

    if (typeof products[i] === 'undefined') {
      break;
    }

    grid[_panel][_column][_row++] = products[i];
  }

  return grid;
}
