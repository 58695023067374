import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiProduct} from '../../shared/model/api-product.model';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {getImageUrl} from '../../api-utils';
import {SignageImageCategory} from '../../shared/enum/image-category.enum';
import {getGrid} from './utils';
import {ApiDataService} from '../../shared/service/api-data.service';

@Component({
  selector: 'app-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss']
})
export class SimpleComponent implements OnInit {
  private static readonly NUM_PANELS = 3;
  private static readonly NUM_COLUMNS = 3;
  private static readonly NUM_ROWS = 4;

  pageImageUrl: string;
  grid: ApiProduct[][][];

  constructor(private apiService: ApiService, private apiDataService: ApiDataService) {
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.pageImageUrl = getImageUrl(this.apiDataService.getSignagePageImage(SignageImageCategory.Simple)) ||
        '/assets/images/page_backgrounds/simple.jpg';

      const products = this.apiDataService.getSignageProducts();

      this.grid = getGrid(products, SimpleComponent.NUM_PANELS, SimpleComponent.NUM_COLUMNS, SimpleComponent.NUM_ROWS);
    });
  }

}
