import {Pipe, PipeTransform} from '@angular/core';
import {getCustomerImage, getImageSized} from '../../utils';

@Pipe({name: 'getImageSized'})
export class GetImageSized implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const size = args[0];

    if (!value || value == null)
      return getCustomerImage(size);

    // If there's `customers` container in
    // value that means we won't do anyhing.
    if (value.indexOf("customers") !== -1)
      return value;

    return getImageSized(value, size)
  }
}
