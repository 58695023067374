import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {ApiData} from '../../shared/model/api-data.model';
import {ApiProduct} from '../../shared/model/api-product.model';
import {LanguageService} from '../../shared/service/language.service';
import {copyMatchingKeyValues, isLoggedIn, Loading} from '../../utils';
import {CartService} from '../../shared/service/cart.service';
import {ApiOrderItemFeature} from '../../shared/model/api.order-item-feature.model';
import * as _ from 'lodash';
import {Title} from '@angular/platform-browser';
import {replaceParams} from '../../shared/service/translations.service';
import iziToast from 'izitoast';
import {Bootbox} from '../../bootbox';

@Component({
  selector: 'product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  apiData: ApiData;
  product: ApiProduct;
  productId: number;
  product_quantity: number = 1;
  product_feature_price: number = 0;
  product_feature_value: number = 1;

  isLoggedIn: boolean = false;
  onlineOrderDisable: boolean;

  constructor(private apiService: ApiService,
              private cartService: CartService,
              private languageService: LanguageService,
              private titleService: Title) {
    const pathname = location.pathname;
    this.productId = parseInt(pathname.substring(pathname.lastIndexOf("/") + 1).split(".")[0]);

    // @ts-ignore
    this.product = {};
  }

  ngOnInit() {
    this.isLoggedIn = isLoggedIn();

    $('body').on('change', '.product-feature', () => {
      this.product_feature_price = 0;
      this.product_feature_value = 1;

      $('body .product-feature').each((index, element) => {
        const el = $(element);

        if (!el.is(':checked'))
          return;

        // Check if it's countable or not
        if (el.attr('data-feature-countable') == "0") {
          this.product_feature_price += parseFloat(el.attr('data-feature-value'));
        } else if (el.attr('data-feature-countable') == "1") {
          this.product_feature_value = parseFloat(el.attr('data-feature-price'));
        }
      });
    });

    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiData = apiData;

      this.onlineOrderDisable = apiData.Settings.WebSiteOrderMode !== 0;

      Loading.show()
        .then(() => this.apiService.getProduct(this.productId))
        .then((data: ApiProduct) => {
          if (data === null)
            throw new Error();

          this.product = new ApiProduct(data);

          this.titleService.setTitle(`${this.apiData.Title} | ${this.product.Name}`);

          // Automatically set currency
          if (this.product.Currency === null) {
            this.product.Currency = "₺";
          }

          // Fill image URL if it's null
          if (this.product.ImageUrl === null) {
            this.product.ImageUrl = this.apiData.ImageUrl;
          }

          try {
            this.product.LingoName = JSON.parse(this.product.LingoName);
            this.product.LingoDescription = JSON.parse(this.product.LingoDescription);

            if (this.product.LingoName === null) {
              return;
            }

            if (this.product.LingoName === null) {
              return;
            }

            if (this.product.LingoName.hasOwnProperty(this.languageService.currentLanguage)) {
              this.product.Name = this.product.LingoName[this.languageService.currentLanguage];
            }

            if (this.product.LingoDescription.hasOwnProperty(this.languageService.currentLanguage)) {
              this.product.Description = this.product.LingoDescription[this.languageService.currentLanguage];
            }

          } catch (e) {
            console.error("Exception caught while parsing lingos:", e);
          }

          if (this.product.ProductFeatureGroups != null) {
            for (let key in this.product.ProductFeatureGroups) {
              if (!this.product.ProductFeatureGroups.hasOwnProperty(key))
                continue;

              const productGroup = this.product.ProductFeatureGroups[key];
              productGroup.ProductFeatureChunk = _.chunk(productGroup.ProductFeature, 2);
            }
          }
        })
        .catch(() => {
          const translations = this.languageService.translations;

          iziToast.error({
            position: 'topRight',
            title: translations['general.error'],
            message: translations['general.unexpected_error_occured']
          });
        })
        .finally(() => {
          Loading.hide();
        });
    });
  }

  onClickAddToCartButton() {
    let errorMessage = "";

    const translations = this.languageService.translations;

    // Before anything else, please check validity
    // of product features.
    const fqErr = this._checkFeaturesQuantity();

    if (fqErr !== null) {

      // If selected feature quantity is less than acceptable
      // quantity...
      if (fqErr.Type === 0) {
        errorMessage = replaceParams(translations['product_details.feature_quantity_short_error'], {
          amount: fqErr.ShortAmount,
          name: fqErr.FeatureGroup.Name
        });
      }

      // If selected feature quantity is more than acceptable
      // quantity...
      if (fqErr.Type === 1) {
        errorMessage = replaceParams(translations['product_details.feature_quantity_long_error'], {
          amount: fqErr.FeatureGroup.Quantity,
          name: fqErr.FeatureGroup.Name
        });
      }

      Bootbox.alert(errorMessage);

      return;
    }

    // This is where we have to convert the product
    // into an order item.
    const orderItem = this.product.createOrderItem();
    orderItem.Quantity = this.product_quantity;
    orderItem.OrderItemFeatures = this._getSelectedFeatures();

    const result = this.cartService.add(orderItem);

    if (result === false) {
      iziToast.error({
        position: 'topRight',
        title: translations['general.error'],
        message: translations['general.cart_cant_add_diff_menu']
      });

      return;
    }

    iziToast.success({
      position: 'topRight',
      title: translations['general.success'],
      message: translations['general.item_added_to_cart']
    });
  }

  _checkFeaturesQuantity() {
    const fgs = this.product.ProductFeatureGroups;

    if (fgs === null || fgs.length === 0) {
      return null;
    }

    let hasError = null;

    // Loop through each feature group
    for (let fg of fgs) {
      if (fg.Quantity === 0) continue;

      let selectQuantity = $(`.feature-group-container[data-feature-group-id=${fg.ProductFeatureGroupID}]`)
        .find('.product-feature:checked').length;

      // If selected quantity is less then minimum
      // required quantity then exit with an error.
      if (selectQuantity < fg.Minimum) {
        hasError = {
          FeatureGroup: fg,
          ShortAmount: fg.Minimum,
          Type: 0
        };

        break;
      }

      // If selected quantity is more then
      // required quantity then exit with an error.
      if (selectQuantity > fg.Quantity) {
        hasError = {
          FeatureGroup: fg,
          ShortAmount: fg.Quantity,
          Type: 1
        };

        break;
      }
    }

    return hasError;
  }

  /**
   * Gets selected features
   * @private
   */
  _getSelectedFeatures() {
    let features: ApiOrderItemFeature[] = [];

    $('.feature-group-container').each((index: number, element: HTMLElement) => {
      let featureGroupId = parseInt($(element).attr('data-feature-group-id'));

      // Find product feature group
      const productFeatureGroup = this.product.ProductFeatureGroups
        .find(i => i.ProductFeatureGroupID === featureGroupId);

      $(element).find('.product-feature:checked').each((index: number, element: HTMLElement) => {
        let featureId = parseInt($(element).attr('data-feature-id'));

        // Find feature in product feature group
        const feature = productFeatureGroup
          .ProductFeature
          .find(i => i.ProductFeatureID === featureId);

        let orderItemFeature = new ApiOrderItemFeature();
        copyMatchingKeyValues(feature, orderItemFeature);


        features.push(orderItemFeature);
      });

    });

    return features;
  }
}
