import {AppStorage, StorageKey} from './storage';
import * as _ from 'lodash';
import {ApiAccount} from './shared/model/api.account.model';

export function toLocaleProperCase(s: string): string {
  if (!s) {
    return '';
  }

  return s.replace(/([^\s:-])([^\s:-]*)/g, ($0, $1, $2) => $1.toLocaleUpperCase() + $2.toLocaleLowerCase());
}

export function getUrlParams(): object {
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = s => decodeURIComponent(s.replace(pl, ' '));
  const query = window.location.search.substring(1);

  const urlParams = {};
  do {
    const match = search.exec(query);
    if (!match) {
      break;
    }

    urlParams[decode(match[1]).toLowerCase()] = decode(match[2]);
  } while (true);

  return urlParams;
}

export function getDivisibleNumberOfElements(arr: any[], divider: number): any[] {
  const len = arr.length;
  return arr.slice(0, len - len % divider);
}

export function getNumberOfSlicedParts(arr: any[], partSizes: number, exact = false): number {
  const division = arr.length / partSizes;

  return exact ? Math.floor(division) : Math.ceil(division);
}

export function getSlicedArrayParts(arr: any[], partSizes: number, exact = false): any[][] {
  const result = [];
  const length = getNumberOfSlicedParts(arr, partSizes, exact);

  for (let i = 0, j = 0; i < length; j = ++i * partSizes) {
    result.push(arr.slice(j, j + partSizes));
  }

  return result;
}

export function getArrayGrid(arr: any[], numRows: number, numCols: number, exact = false): any[][][] {
  const result = [];
  const partSize = numRows * numCols;
  const length = getNumberOfSlicedParts(arr, partSize, exact);

  for (let i = 0, j = 0; i < length; j = ++i * partSize) {
    result.push([]);
    for (let k = 0, l = 0; k < numRows; l = ++k * numCols + j) {
      result[i].push(arr.slice(l, l + numCols));
    }
  }

  return result;
}

export function randInt(max: number): number {
  return Math.floor(Math.random() * max);
}

export function selectRandFromArray(arr: any[], count: number): any[] {
  const _arr = [...arr];
  let len = arr.length;

  const selected = [];

  for (let i = 0; i < count && len > 0; i++) {
    const j = randInt(len);
    selected.push(_arr[j]);
    _arr.splice(j, 1);
    len--;
  }

  return selected;
}

export function selectRandFromNestedArray(baseArray: object[], nestedArrayName: string, count: number): any[] {
  const arr = [];
  let len = baseArray.length;

  for (let i = 0; i < len; i++) {
    arr.push([...baseArray[i][nestedArrayName]]);
  }

  const selected = [];

  for (let i = 0; i < count && len > 0; i++) {
    const j = randInt(len);
    const arr1 = arr[j];

    const len1 = arr1.length;

    if (len1 < 1) {
      arr.splice(j, 1);
      len--;
      i--;
      continue;
    }

    const k = randInt(len1);
    selected.push(arr1[k]);
    arr1.splice(k, 1);
  }

  return selected;
}

/**
 * @see {@link https://gist.github.com/muratcorlu/3698167}
 */
export function slugify(s: string): string {
  let str = s;

  const trMap = {
    \u00E7\u00C7: 'c',
    \u011F\u011E: 'g',
    \u0131\u0130: 'i',
    \u00F6\u00D6: 'o',
    \u015F\u015E: 's',
    \u00FC\u00DC: 'u',
  };
  for (const key in trMap) {
    if (trMap.hasOwnProperty(key)) {
      str = str.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
    }
  }

  return str
    .replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
    .replace(/\s/gi, '-') // convert spaces to dashes
    .replace(/[-]+/gi, '-') // trim repeated dashes
    .toLowerCase();
}

export function ellipsisLongText(s: string, limit: number): string {
  if (!s) {
    return '';
  }

  if (s.length <= limit) {
    return s;
  } else {
    return s.slice(0, limit) + '...';
  }
}

/**
 * Gets image URL for desired size.
 * @param input Image URL
 * @param size Destination size
 */
export function getImageSized(input: string, size: number) {
  // If input is null then return customer image
  if (input === null) {
    return getCustomerImage(size);
  }

  if (size === null || typeof size === 'undefined') {
    size = 400;
  }

  // First get last path of the URL
  let lastIndexOfPathSeperator = input.lastIndexOf('/');
  const lastPart = input.substring(lastIndexOfPathSeperator + 1);

  // Get index of extension dot
  let lastIndexOfExtDot = lastPart.lastIndexOf('.');

  let parsedFilename = lastPart.substring(lastIndexOfExtDot, 0);
  let parsedExtension = lastPart.substring(lastIndexOfExtDot + 1);

  input = `${input.substring(0, lastIndexOfPathSeperator)}/${parsedFilename}x${size}.${parsedExtension}`;

  return input;
}

export function getCustomerImage(size: number) {
  const place = AppStorage.getItem(StorageKey.PLACE_DETAIL);
  let input = place.LogoUrl.split('/');
  input = input[input.length - 1];
  input = input.split('.');

  size = size || 200;

  return `http://menuluxstorage.blob.core.windows.net/customers/${input[0]}x${size}.${input[1]}`;
}

export function getAddressErrorMessage(addressBundle, translations) {
  const telephone = addressBundle.telephone;
  const address = addressBundle.address;
  const city = addressBundle.city;
  const district = addressBundle.district;
  const postcode = addressBundle.postcode;

  const telephoneError = getTelephoneErrorMessage(telephone, translations);
  if (telephoneError != null) {
    return telephoneError;
  }

  if (city === '-1') {
    return translations['general.city_error_c'];
  }

  if (district === '-1') {
    return translations['general.district_error_c'];
  }

  if (_.isEmpty(postcode)) {
    return translations['general.postcode_error_c'];
  }

  if (_.isEmpty(address)) {
    return translations['general.address_error_c'];
  }

  return null;
}

export function getTelephoneErrorMessage(telephone, translations) {
  if (!_.isString(telephone)) {
    return translations['general.telephone_invalid'];
  }

  if (_.isEmpty(telephone)) {
    return translations['general.error_phone_cannot_be_empty'];
  }

  if (telephone.length < 9) {
    return translations['general.telephone_invalid'];
  }

  return null;
}

export function getPasswordErrorMessage(password, translations) {
  if (!_.isString(password)) {
    return translations['general.password_error_invalid'];
  }
  if (_.isEmpty(password)) {
    return translations['general.error_password_cannot_be_empty'];
  }
  if (password.length < 6) {
    return translations['general.password_error_too_short'];
  }
}

/**
 * Copies values from one to another if one key exists
 * on both objects.
 * @param from {any}
 * @param to {any}
 */
export function copyMatchingKeyValues(from: any, to: any) {
  Object.keys(from).forEach(i => {
    if (typeof to[i] === 'undefined')
      return;

    to[i] = from[i];
  });
}

/**
 * Generates a random decimal number.
 * @return {number}
 */
export function generateUniqueID() {
  return Math.round(Math.random() * 10e10);
}

/**
 * Returns logged in user
 */
export function getLoggedInUser(): ApiAccount {
  return AppStorage.getItem(StorageKey.USER) as ApiAccount;
}

/**
 * Returns true if an user is
 * logged in.
 */
export function isLoggedIn(): boolean {
  const user = getLoggedInUser();
  return user !== null;
}

export class Loading {
  static show() {
    return new Promise((resolve) => {
      $('.afterloader').show();
      resolve();
    });
  }

  static hide() {
    $('.afterloader').hide();
  }
}
