export declare const bootbox: any;

export class Bootbox {
  /**
   *
   */
  static alert (...opts) {
    let options = Bootbox.parseOptions(opts);
    bootbox.alert(options);
  }

  static confirm (...opts) {
    let options = Bootbox.parseOptions(opts);
    bootbox.confirm(options);
  }

  static prompt (...opts) {
    let options = Bootbox.parseOptions(opts);
    bootbox.prompt(options);
  }

  private static parseOptions(_opts) {
    let options = {
      message: '',
      callback: ''
    };

    const firstArgumentType = typeof _opts[0];

    if (firstArgumentType === 'string') {
      options.message = _opts[0];

      if (typeof _opts[1] === 'function') {
        options.callback = _opts[1];
      }

    } else if (firstArgumentType === 'object') {
      options = _opts[0];
    }

    return options;
  }
}
