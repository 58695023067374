import {ApiData} from '../model/api-data.model';
import {
  CustomerDisplayImageCategory,
  GalleryImageCategory,
  LogoImageCategory,
  SignageImageCategory,
  WebsiteImageCategory
} from '../enum/image-category.enum';
import {ApiImage} from '../model/api-image.model';
import {getDivisibleNumberOfElements, selectRandFromArray, selectRandFromNestedArray} from '../../utils';
import {ApiFavourite} from '../model/api-favourite.model';
import {ApiGallery} from '../model/api-gallery.model';
import {ImageType} from '../enum/image-type.enum';
import {ApiMenuGroup} from '../model/api-menu-group.model';
import {ApiProduct} from '../model/api-product.model';
import {Injectable} from '@angular/core';

@Injectable()
export class ApiDataService {
  private logoImages: Map<LogoImageCategory, ApiImage>;

  constructor() {
  }

  private _apiData: ApiData;

  public set apiData(value: ApiData) {
    if (value) {
      this._apiData = value;
    }
  }

  public getLogo(logoImageCategory: LogoImageCategory): ApiImage {
    if (this.logoImages === undefined) {
      this.initLogoImages();
    }

    return this.logoImages.get(logoImageCategory);
  }

  public getSliderImages(desiredImages: number): ApiImage[] {
    const sliderImages = this._apiData.Images.filter((value) => value.Category === WebsiteImageCategory.Slider);

    // noinspection JSAnnotator
    return selectRandFromArray(sliderImages as [], desiredImages);
  }

  public getFavourites(desiredImages: number): ApiFavourite[] {
    return selectRandFromArray(this._apiData.Favourites, desiredImages);
  }

  public getGalleryImages(desiredImages: number): ApiGallery[] {
    if (!this._apiData || !this._apiData.Gallery) {
      return []; // Ya da başka bir hata işleme mekanizması
    }

    const images = this._apiData.Gallery.filter((value) => value.Type === ImageType.Gallery);
    const maxImages = Math.min(desiredImages, images.length);

    return getDivisibleNumberOfElements(selectRandFromArray(images, maxImages), 3);
  }

  public getMenuGroups(): ApiMenuGroup[] {
    if (!this._apiData || !this._apiData.Menu || !this._apiData.Menu.MenuGroup) {
      return [];
    }

    return this._apiData.Menu.MenuGroup.filter(value => value.Products && value.Products.length > 0);
  }


  public getMenuProducts(desiredImages: number): ApiProduct[] {
    const menu = this._apiData.Menu;
    const menuGroup = menu.MenuGroup;

    return selectRandFromNestedArray(menuGroup, 'Products', desiredImages);
  }

  public getSignageProducts(): ApiProduct[] {
    return [].concat(...this._apiData.Menu.MenuGroup.map(value => value.Products));
  }

  public getImages(category: SignageImageCategory |
    WebsiteImageCategory |
    LogoImageCategory |
    CustomerDisplayImageCategory |
    GalleryImageCategory): ApiImage[] {
    return this._apiData.Images.filter(value => value.Category === category);
  }

  public getPageImage(category: WebsiteImageCategory): ApiImage {
    return this._apiData.Images.find((image) => image.Category === category);
  }

  public getCurrency(): string {
    return this._apiData.Menu.Currency;
  }

  public getLatLng(): { lat: number, lng: number } {
    const latLngStr = this._apiData.LatLng.split(';');
    return {
      lat: Number(latLngStr[0]),
      lng: Number(latLngStr[1]),
    };
  }

  public getSignagePageImage(category: SignageImageCategory): ApiImage {
    return this._apiData.Images.find((value) => value.Category === category);
  }

  private findLogo(logoImageCategory: LogoImageCategory): ApiImage {
    return this._apiData.Logos.find((logo) => logo.Category === logoImageCategory);
  }

  private initLogoImages() {
    this.logoImages = new Map<LogoImageCategory, ApiImage>();

    const baseLogoImage = {ImageUrl: this._apiData.ImageUrl} as ApiImage;

    const defaultLogoImage = this.findLogo(LogoImageCategory.Default) || baseLogoImage;
    const whiteTextLogoImage = this.findLogo(LogoImageCategory.WhiteText) || defaultLogoImage;
    const blackTextLogoImage = this.findLogo(LogoImageCategory.BlackText) || defaultLogoImage;
    const footerLogoImage = this.findLogo(LogoImageCategory.Footer) || whiteTextLogoImage;

    this.logoImages.set(LogoImageCategory.Default, defaultLogoImage);
    this.logoImages.set(LogoImageCategory.WhiteText, whiteTextLogoImage);
    this.logoImages.set(LogoImageCategory.BlackText, blackTextLogoImage);
    this.logoImages.set(LogoImageCategory.Footer, footerLogoImage);
  }
}
