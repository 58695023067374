import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {LightboxModule} from 'ngx-lightbox';

import {AppRoutingModule} from './app-routing.module';

import {WebsiteModule} from './website/website.module';
import {SignageModule} from './signage/signage.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './shared/components/header/header.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {ApiService} from './shared/service/api.service';
import {MainPipe} from './shared/pipes/main.pipe';
import {LegacyRoutingModule} from './legacy-routing.module';
import {ApiDataService} from './shared/service/api-data.service';
import {BasketComponent} from './website/basket/basket.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from './shared/service/translations.service';
import {LanguageService} from './shared/service/language.service';
import {EventBroadcasterService} from './shared/service/event-broadcaster.service';
import {AuthGuard} from './shared/guard/auth.guard';
import {CartService} from './shared/service/cart.service';
import {OrderService} from './shared/service/order.service';
import {GAService} from './shared/service/ga.service';
import {EnvironmentService} from './shared/service/environment.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    LegacyRoutingModule,
    WebsiteModule,
    SignageModule,
    AppRoutingModule,
    LightboxModule,
    MainPipe,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: []
      }
    })
  ],
  entryComponents: [
    BasketComponent,
  ],
  providers: [
    ApiService,
    ApiDataService,
    CartService,
    GAService,
    OrderService,
    EventBroadcasterService,
    LanguageService,
    EnvironmentService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function TranslationLoaderFactory() {
  return new TranslationLoaderService();
}
