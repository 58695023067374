class AppStorageSingleton {
  private readonly _storage: object;
  private readonly _instance: AppStorageSingleton;
  private _storageHandler: any;

  constructor(storageType: StorageType) {
    if (this._instance !== undefined) {
      return this._instance;
    }

    this._storage = {};
    this._instance = this;

    if (storageType === StorageType.Local) {
      this._storageHandler = localStorage
    } else if (storageType === StorageType.Session) {
      this._storageHandler = sessionStorage
    }
  }

  static _isEmpty(value) {
    return value == null || value === '' || value === 'null' || value === 'undefined';
  }

  setItem(key, value) {
    if (AppStorageSingleton._isEmpty(value)) {
      return false;
    }

    this._storageHandler.setItem(key, JSON.stringify(value));
    this._storage[key] = value;

    return true;
  };

  getItem(key) {
    if (this._storage.hasOwnProperty(key)) {
      return this._storage[key];
    }

    let value = this._storageHandler.getItem(key);

    if (AppStorageSingleton._isEmpty(value)) {
      return null;
    }

    try {
      value = JSON.parse(value);
    } catch (e) {
    } finally {
      this._storage[key] = value;
    }

    return value;
  };

  removeItem(key) {
    delete this._storage[key];
    this._storageHandler.removeItem(key);
  };

  clear(...exceptions) {
    for (const key of Object.keys(localStorage)) {
      if (!exceptions.includes(key)) {
        this.removeItem(key);
      }
    }
  }
}

enum StorageType {
  Local = 1,
  Session = 2
}

export const AppStorage = Object.freeze(new AppStorageSingleton(StorageType.Local));

export const SessionAppStorage = Object.freeze(new AppStorageSingleton(StorageType.Session));

export const StorageKey = Object.freeze({
  CART: 'Cart',
  SELECTED_MENU_ID: 'SelectedMenuID',
  LANGUAGE_CODE: 'LanguageCode',
  PLACE_DETAIL: 'PlaceDetail',
  USER: 'User',
  MENU_TYPE: 'MenuType'
});
