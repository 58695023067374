import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AppStorage, StorageKey} from '../../storage';

@Injectable()
export class AuthGuard implements CanActivate {
  private readonly user: object;

  constructor() {
    this.user = AppStorage.getItem(StorageKey.USER);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.user !== null;
  }
}
