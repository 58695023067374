import {Component, OnInit} from '@angular/core';
import {ApiData} from '../../shared/model/api-data.model';
import {ApiService} from '../../shared/service/api.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {getImageUrl, setMissingImageUrls} from '../../api-utils';
import {LogoImageCategory, SignageImageCategory} from '../../shared/enum/image-category.enum';
import {ApiMenuGroup} from '../../shared/model/api-menu-group.model';
import {parseMenuGroups} from './utils';
import {ApiDataService} from '../../shared/service/api-data.service';

@Component({
  selector: 'app-blackboard',
  templateUrl: './blackboard.component.html',
  styleUrls: ['./blackboard.component.scss']
})
export class BlackboardComponent implements OnInit {

  pageImageUrl: string;
  apiData: ApiData;
  columns: ApiMenuGroup[][];
  logoUrl: string;

  constructor(private apiService: ApiService, private apiDataService: ApiDataService) {
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.pageImageUrl = getImageUrl(this.apiDataService.getSignagePageImage(SignageImageCategory.BlackView)) ||
        '/assets/images/page_backgrounds/blackboard.jpg';

      const menuGroups = this.apiDataService.getMenuGroups();
      setMissingImageUrls(menuGroups, apiData.ImageUrl);
      this.columns = parseMenuGroups(menuGroups);

      this.logoUrl = getImageUrl(this.apiDataService.getLogo(LogoImageCategory.WhiteText));

      this.apiData = apiData;
    });
  }

}
