import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: 'index.html', redirectTo: '/'},
  {path: 'gallery.html', redirectTo: '/gallery'},
  {path: 'menu.html', redirectTo: '/menu'},
  {path: 'reservation.html', redirectTo: '/reservation'},
  {path: 'contact.html', redirectTo: '/contact'},
  {path: 'blackboard.html', redirectTo: '/signage/blackboard'},
  {path: 'circles.html', redirectTo: '/signage/circles'},
  {path: 'departure.html', redirectTo: '/signage/departure'},
  {path: 'simple.html', redirectTo: '/signage/simple'},
  {path: 'mobile.html', redirectTo: '/signage/mobile'},
  {path: 'modern.html', redirectTo: '/signage/modern'},
  {path: 'slider.html', redirectTo: '/signage/slider'},
  {path: 'basket.html', redirectTo: '/basket'},
  {path: 'signup.html', redirectTo: '/signup'},
  {path: 'calldisplay.html', redirectTo: '/signage/waiter-display'},
  {path: 'product-details.html', redirectTo: '/product-details'},
  {path: 'profile-details.html', redirectTo: '/profile-details'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LegacyRoutingModule {
}
