import {Component, OnInit, Renderer2} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiData} from '../../shared/model/api-data.model';
import {ApiMenuGroup} from '../../shared/model/api-menu-group.model';
import {Lightbox} from 'ngx-lightbox';
import {ApiDataService} from '../../shared/service/api-data.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})

export class MobileComponent implements OnInit {
  menuGroups: ApiMenuGroup[];
  apiData: ApiData;
  headerLogoUrl: string;
  footerLogoUrl: string;

  private albums = [];

  constructor(private apiService: ApiService,
              private apiDataService: ApiDataService,
              private renderer: Renderer2, private lightbox: Lightbox) {
  }

  ngOnInit() {
    /*
    this.renderer.removeClass(document.body, 'no-overflow');

    this.apiService.apiData(ApiFunctionName.GetSignage).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      const menuGroups = this.menuGroups = this.apiDataService.getMenuGroups();

      for (const menuGroup of menuGroups) {
        const products = menuGroup.Products;

        setMissingImageUrls(products as any, apiData.ImageUrl);

        this.albums.push(createAlbum(products as any));
      }

      this.headerLogoUrl = getImageUrl(this.apiDataService.getLogo(LogoImageCategory.WhiteText));
      this.footerLogoUrl = getImageUrl(this.apiDataService.getLogo(LogoImageCategory.Footer));

      this.apiData = apiData;
    });
    */

    location.href = 'http://mobile.' + window.location.hostname;
  }

  showLightbox(albumIndex: number, curIndex: number) {
    this.lightbox.open(this.albums[albumIndex], curIndex);
  }

}
