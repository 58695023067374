import {NgModule} from '@angular/core';
import {IndexComponent} from './index/index.component';
import {RouterModule, Routes} from '@angular/router';
import {BasketComponent} from './basket/basket.component';
import {ProductDetailsComponent} from './product-details/product-details.component';
import {SignupComponent} from './signup/signup.component';
import {ProfileDetailsComponent} from './profile-details/profile-details.component';
import {AboutComponent} from './about/about.component';
import {PoliciesComponent} from './policies/policies.component';

export const websiteRoutes: Routes = [
  {path: '', component: IndexComponent, pathMatch: 'full'},
  {path: 'basket', component: BasketComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'product-details/:product_id', component: ProductDetailsComponent},
  {path: 'profile-details', component: ProfileDetailsComponent},
  {path: 'about', component: AboutComponent},
  {path: 'policies', component: PoliciesComponent}
];

@NgModule({
  imports: [RouterModule.forChild(websiteRoutes),RouterModule.forRoot(websiteRoutes)],
  exports: [RouterModule]
})
export class WebsiteRoutingModule {
}
