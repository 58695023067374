import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiData} from '../../shared/model/api-data.model';
import {ApiImage} from '../../shared/model/api-image.model';
import {ApiProduct} from '../../shared/model/api-product.model';
import {ApiGallery} from '../../shared/model/api-gallery.model';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {Lightbox} from 'ngx-lightbox';
import {createAlbum} from '../../api-utils';
import {copyMatchingKeyValues, getImageSized, getLoggedInUser, isLoggedIn, Loading, slugify} from '../../utils';
import {ApiDataService} from '../../shared/service/api-data.service';
import {ApiMenuGroup} from '../../shared/model/api-menu-group.model';
import {CartService} from '../../shared/service/cart.service';
import {LanguageService} from '../../shared/service/language.service';
import {ApiOrderItemFeature} from '../../shared/model/api.order-item-feature.model';
import {Title} from '@angular/platform-browser';
import * as _ from 'lodash';
import iziToast from 'izitoast';
import {replaceParams} from '../../shared/service/translations.service';
import {Bootbox} from '../../bootbox';
import {ApiMenu} from '../../shared/model/api-menu.model';
import {EventBroadcasterService} from '../../shared/service/event-broadcaster.service';
import {SessionAppStorage, StorageKey} from '../../storage';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  static readonly NUM_SLIDER_IMAGES = 3;
  static readonly NUM_GALLERY_IMAGES = 9;

  private galleryAlbum = [];

  selectedMenuName: string;
  pageImageUrl: string;
  apiData: ApiData;
  menu: { products: ApiProduct[], currency: string };
  slider: ApiImage[] = [];
  gallery: ApiGallery[] = [];

  currency: string;
  menuGroups: ApiMenuGroup[] = [];
  products: ApiProduct[] = [];

  selectMenu: (index: number) => void;
  selectedMenuIndex = 0;

  isSmallScreen = false;

  selectedProduct: ApiProduct;
  selected_product_quantity: number = 1;
  selected_product_feature_price: number = 0;
  selected_product_feature_value: number = 1;

  isLoggedIn: boolean = false;
  onlineOrderDisable: boolean;

  customerMenus: ApiMenu[] = [];
  showMenuName: boolean = false;

  constructor(private apiService: ApiService,
              private apiDataService: ApiDataService,
              private cartService: CartService,
              private eventService: EventBroadcasterService,
              private languageService: LanguageService,
              private titleService: Title,
              private _lightbox: Lightbox) {

    this.selectedProduct = new ApiProduct();
  }

  ngOnInit() {
    this.isLoggedIn = isLoggedIn();

    $('.modal').on('shown.bs.modal', (e) => {
      $(e.currentTarget).before($('.modal-backdrop'));
    });

    $('.category-navigation').on('click', e => {
      const that = $(e.currentTarget);
      const categoryWrapper = $('.category-wrapper');
      const direction = that.attr('data-direction');

      let directionAmount = categoryWrapper.width();

      if (direction === 'left') {
        directionAmount *= -1;
      }

      categoryWrapper.animate({scrollLeft: `+=${directionAmount}`}, 750);
    });

    $('body').on('change', '.product-feature', () => {
      this.selected_product_feature_price = 0;
      this.selected_product_feature_value = 1;

      $('body .product-feature').each((index, element) => {
        const el = $(element);

        if (!el.is(':checked')) {
          return;
        }

        // Check if it's countable or not
        if (el.attr('data-feature-countable') == '0') {
          this.selected_product_feature_price += parseFloat(el.attr('data-feature-value'));
        } else if (el.attr('data-feature-countable') == '1') {
          this.selected_product_feature_value = parseFloat(el.attr('data-feature-price'));
        }
      });
    });

    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiData = apiData;

      this.onlineOrderDisable = apiData.Settings.WebSiteOrderMode !== 0;
      // The ugliest feature implementation
      if (!this.isLoggedIn) {
        this.initializePage();
        return;
      }

      // Get user detail
      const user = getLoggedInUser();

      // Check if user has MenuID value is set.
      if (user.MenuID === null || user.Menus === null || user.Menus.length === 0) {
        this.initializePage();
        return;
      }

      // Here, we are override user.MenuID field
      // to manipulate default menu behaviour
      const selectedMenuId = SessionAppStorage.getItem(StorageKey.SELECTED_MENU_ID);
      if (selectedMenuId !== null) {
        user.MenuID = selectedMenuId;
      }

      // Get menu
      Loading.show()
        .then(() => this.apiService.getMenuSummary())
        .then((menus: ApiMenu[]) => {
          this.customerMenus = menus;
        })
        .then(() => {
          let accountMenuIds = user.Menus.split(',').map(i => parseInt(i));
          this.customerMenus = this.customerMenus.filter(i => accountMenuIds.includes(i.MenuID));
        })
        .then(() => this.apiService.getMenu(user.MenuID))
        .then((menu: ApiMenu) => {
          this.apiData.Menu = menu;
          this.showMenuName = true;
        })
        .finally(() => {
          this.initializePage();

          Loading.hide();
        });
    });
  }

  initializePage() {
    this.slider = this.apiDataService.getSliderImages(IndexComponent.NUM_SLIDER_IMAGES);

    this.gallery = this.apiDataService.getGalleryImages(IndexComponent.NUM_GALLERY_IMAGES);
    this.galleryAlbum = createAlbum(this.gallery);

    // Currency of the menu. This helps to show currency
    // of the prices of the products.
    this.currency = this.apiDataService.getCurrency();

    const menuGroups = this.menuGroups = this.apiDataService.getMenuGroups();

    for (const menuGroup of menuGroups) {
      const slug = slugify(menuGroup.Name);

      menuGroup.selected = false;
      menuGroup.slug = slug;

      for (const product of menuGroup.Products) {
        product.slug = slug;

        if (!product.ImageUrl) {
          product.ImageUrl = this.apiData.ImageUrl;
          product.ImageUrl = getImageSized(product.ImageUrl, 400);
        }
      }

      this.products.push(...menuGroup.Products);
    }

    this.selectMenu = this.setSelected.bind(this, menuGroups);
    this.selectMenu(0);

    this.titleService.setTitle(this.apiData.Title);
  }

  setSelected(menuGroupArray: Array<{ selected: boolean }>, index: number) {
    menuGroupArray[this.selectedMenuIndex].selected = false;
    menuGroupArray[index].selected = true;

    this.selectedMenuIndex = index;
  }

  galleryLightbox(index: number) {
    this._lightbox.open(this.galleryAlbum, index);
  }

  onClickMenuGroup(index: number) {
    this.selectMenu(index);
    this.closeNav();
  }

  onClickProductAdd(product: ApiProduct) {
    const translations = this.languageService.translations;

    const that = this;

    const orderItem = new ApiProduct(product).createOrderItem();
    if (!this.cartService.canAddProduct(orderItem)) {
      iziToast.error({
        position: 'topRight',
        title: translations['general.error'],
        message: translations['general.cart_cant_add_diff_menu']
      });

      return;
    }

    Loading.show()
      .then(() => this.apiService.getProduct(product.ProductID))
      .then((product: ApiProduct) => {
        that.selectedProduct = new ApiProduct(product);

        for (let key in this.selectedProduct.ProductFeatureGroups) {
          if (!this.selectedProduct.ProductFeatureGroups.hasOwnProperty(key)) {
            continue;
          }

          const productGroup = this.selectedProduct.ProductFeatureGroups[key];
          productGroup.ProductFeatureChunk = _.chunk(productGroup.ProductFeature, 2);
        }

        this.selected_product_quantity = 1;

        // @ts-ignore
        $('#productFeatureModal').modal('show');
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.selectedProduct.ProductFeatureGroups.sort((a, b) => a.ViewIndex - b.ViewIndex);

        Loading.hide();
      });
  }

  onClickAddToCartButton() {
    let errorMessage = '';

    const translations = this.languageService.translations;

    // Before anything else, please check validity
    // of product features.
    const fqErr = this._checkFeaturesQuantity();

    if (fqErr !== null) {

      // If selected feature quantity is less than acceptable
      // quantity...
      if (fqErr.Type === 0) {
        iziToast.error({
          position: 'topRight',
          title: translations['general.error'],
          message: replaceParams(translations['product_details.feature_quantity_short_error'], {
            amount: fqErr.ShortAmount,
            name: fqErr.FeatureGroup.Name
          })
        });
      }

      // If selected feature quantity is more than acceptable
      // quantity...
      if (fqErr.Type === 1) {
        iziToast.error({
          position: 'topRight',
          title: translations['general.error'],
          message: replaceParams(translations['product_details.feature_quantity_long_error'], {
            amount: fqErr.FeatureGroup.Quantity,
            name: fqErr.FeatureGroup.Name
          })
        });
      }

      Bootbox.alert(errorMessage);

      return;
    }
    // This is where we have to convert the product
    // into an order item.
    const orderItem = this.selectedProduct.createOrderItem();
    orderItem.Quantity = this.selected_product_quantity;
    orderItem.OrderItemFeatures = this._getSelectedFeatures();

    this.cartService.add(orderItem);

    iziToast.success({
      position: 'topRight',
      title: translations['general.success'],
      message: translations['general.item_added_to_cart']
    });

    // @ts-ignore
    $('#productFeatureModal').modal('hide');
  }

  getImageSizedWrapper(input: string, size: number) {
    return getImageSized(input, size);
  }

  changeSelectedMenu(menu: ApiMenu) {
    SessionAppStorage.setItem(StorageKey.SELECTED_MENU_ID, menu.MenuID);

    window.location.reload();
  }

  _checkFeaturesQuantity() {
    const fgs = this.selectedProduct.ProductFeatureGroups;

    if (fgs === null || fgs.length === 0) {
      return null;
    }

    let hasError = null;

    // Loop through each feature group
    for (let fg of fgs) {
      if (fg.Quantity === 0 && fg.Minimum === 0) {
        continue;
      }

      let selectQuantity = $(`.feature-group-container[data-feature-group-id=${fg.ProductFeatureGroupID}]`)
        .find('.product-feature:checked').length;

      // If selected quantity is less then minimum
      // required quantity then exit with an error.
      if (selectQuantity < fg.Minimum) {
        hasError = {
          FeatureGroup: fg,
          ShortAmount: fg.Minimum,
          Type: 0
        };

        break;
      }

      // If selected quantity is more then
      // required quantity then exit with an error.
      if (selectQuantity > fg.Quantity) {
        hasError = {
          FeatureGroup: fg,
          ShortAmount: fg.Quantity,
          Type: 1
        };

        break;
      }
    }

    return hasError;
  }

  /**
   * Gets selected features
   * @private
   */
  _getSelectedFeatures() {
    let features: ApiOrderItemFeature[] = [];

    $('.feature-group-container').each((index: number, element: HTMLElement) => {
      let featureGroupId = parseInt($(element).attr('data-feature-group-id'));

      // Find product feature group
      const productFeatureGroup = this.selectedProduct.ProductFeatureGroups
        .find(i => i.ProductFeatureGroupID === featureGroupId);

      $(element).find('.product-feature:checked').each((index: number, element: HTMLElement) => {
        let featureId = parseInt($(element).attr('data-feature-id'));

        // Find feature in product feature group
        const feature = productFeatureGroup
          .ProductFeature
          .find(i => i.ProductFeatureID === featureId);

        let orderItemFeature = new ApiOrderItemFeature();
        copyMatchingKeyValues(feature, orderItemFeature);


        features.push(orderItemFeature);
      });

    });

    return features;
  }

  closeNav() {
    document.getElementById('headerNav').style.marginLeft = '-100vw';
    document.getElementById('bg-left-menu').style.display = 'none';
  }
}
