import {ApiProductFeatureGroup} from './api.product-feature-group';
import {ApiOrderItem} from './api.order-item.model';
import {copyMatchingKeyValues} from '../../utils';

export class ApiProduct {
  ProductID: number;
  CustomerID: number;
  DepartmentID: number;
  ReportGroupID: number;
  MenuID: number;
  UniqueID: string;
  Name: string;
  Code: string;
  ProductName: string;
  MenuName: string;
  Barcode: string;
  ImageUrl: string;
  VideoUrl: string;
  UnitPrice: number;
  TotalPrice: number;
  Price: number;
  Price2: number;
  Price3: number;
  Tax: number;
  Tax2: number;
  Tax3: number;
  Group: number;
  ViewIndex: number;
  CookingTime: number;
  Unit: number;
  Type: number;
  Status: number;
  Index: number;
  Calorie: number;
  Tag: number;
  Description: string;
  Tags: string;
  Apps: string;
  Currency: string;
  WebUrl: string;
  ProductFeatureGroups: ApiProductFeatureGroup[];
  LingoName: string;
  LingoDescription: string;
  Allergens: string;

  slug: string;

  constructor(product?) {
    // Copy properties of product
    // if provided.
    if (product) {
      Object.assign(this, product);
    }
  }

  public createOrderItem(): ApiOrderItem {
    let orderItem = new ApiOrderItem();
    copyMatchingKeyValues(this, orderItem);

    return orderItem;
  }
}
