import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {ApiProduct} from '../../shared/model/api-product.model';
import {SignageImageCategory} from '../../shared/enum/image-category.enum';
import {getImageUrl, setMissingImageUrls} from '../../api-utils';
import {getSlicedArrayParts} from '../../utils';
import {ApiDataService} from '../../shared/service/api-data.service';

@Component({
  selector: 'app-circles',
  templateUrl: './circles.component.html',
  styleUrls: [
    './circles.component.scss'
  ]
})
export class CirclesComponent implements OnInit {
  private static readonly NUM_ROWS = 2;
  private static readonly NUM_COLUMNS = 4;

  products: ApiProduct[] = [];
  productSets: ApiProduct[][];
  pageImageUrl: string;

  constructor(private apiService: ApiService, private apiDataService: ApiDataService) {
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.products = this.apiDataService.getSignageProducts();
      setMissingImageUrls(this.products, apiData.ImageUrl);
      this.productSets = getSlicedArrayParts(this.products, CirclesComponent.NUM_ROWS * CirclesComponent.NUM_COLUMNS, true);

      this.pageImageUrl = getImageUrl(this.apiDataService.getSignagePageImage(SignageImageCategory.Circles)) ||
        '/assets/images/page_backgrounds/circles.jpg';
    });
  }

}
