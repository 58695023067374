/**
 * Google Analytics service wrapper
 */
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ApiFunctionName} from '../enum/api-function-name.enum';
import {NavigationEnd, Router} from '@angular/router';
import {ApiData} from '../model/api-data.model';

@Injectable()
export class GAService {
  private isEnabled = false;
  private readonly _ga: any;

  constructor(private apiService: ApiService,
              private router: Router) {

    const that = this;

    if (window.hasOwnProperty('ga')) {
      this._ga = window['ga'];
    }

    this.apiService.apiData(ApiFunctionName.GetMenu)
      .subscribe(data => that.onSubscriptionCompleted(data));

    this.router.events.subscribe(e => {
      if (!(e instanceof NavigationEnd)) {
        return;
      }

      that.onRouteEvent(e.url);
    });
  }

  onSubscriptionCompleted(data: ApiData) {
    const code = data.Settings.WebSiteAnalyticsCode;

    if (code == null)
      return;

    if (code.length < 1)
      return;

    this._ga('create', code);
    this.isEnabled = true;

    this.onRouteEvent(location.pathname);
  }

  onRouteEvent(url) {
    if (!this.isEnabled)
      return;

    this._ga('send', 'pageview', url);
  }
}
