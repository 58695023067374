import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {LanguageService} from '../../shared/service/language.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {ApiData} from '../../shared/model/api-data.model';
import {ApiService} from '../../shared/service/api.service';
import {Loading} from '../../utils';
import {ApiCustomerImage} from '../../shared/model/api.customer-image';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  apiData: ApiData;

  aboutUsImageUrl: string = "";
  aboutUsDescription: string = "";

  constructor(private apiService: ApiService,
              private languageService: LanguageService,
              private titleService: Title) {
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      const translations = this.languageService.translations;

      this.apiData = apiData;

      this.aboutUsDescription = apiData.Description;

      // Set page title
      this.titleService.setTitle(`${this.apiData.Title} | ${translations['about.title']}`);

      Loading.show()
        .then(() => this.apiService.getGalleryImages(0))
        .then((data: [ApiCustomerImage]) => {
          this.aboutUsImageUrl = apiData.ImageUrl;

          if (data !== null || data.length > 0) {
            // Return first image
            this.aboutUsImageUrl = data[0].ImageUrl;
          }
        })
        .finally(() => Loading.hide());
    });
  }
}
