import {NgModule} from '@angular/core';
import {LocaleProperCase} from './locale-proper-case.pipe';
import {CommonModule} from '@angular/common';
import {Slugify} from './slugify.pipe';
import {EllipsisLongText} from './ellipsis-long-text.pipe';
import {LocaleLowerCase} from './locale-lower-case.pipe';
import {GetImageSized} from './get-image-size.pipe';

@NgModule({
  declarations: [
    LocaleProperCase,
    LocaleLowerCase,
    Slugify,
    EllipsisLongText,
    GetImageSized
  ],
  imports: [CommonModule],
  exports: [
    LocaleProperCase,
    LocaleLowerCase,
    Slugify,
    EllipsisLongText,
    GetImageSized
  ]
})
export class MainPipe {
}
