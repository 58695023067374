import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CirclesComponent} from './circles/circles.component';
import {DepartureComponent} from './departure/departure.component';
import {BlackboardComponent} from './blackboard/blackboard.component';
import {SimpleComponent} from './simple/simple.component';
import {MobileComponent} from './mobile/mobile.component';
import {ModernComponent} from './modern/modern.component';
import {SliderComponent} from './slider/slider.component';
import {WaiterDisplayComponent} from './waiter-display/waiter-display.component';

const routes: Routes = [
  {
    path: 'signage', children:
      [
        {path: '', redirectTo: '/', pathMatch: 'full'},
        {path: 'circles', component: CirclesComponent},
        {path: 'departure', component: DepartureComponent},
        {path: 'blackboard', component: BlackboardComponent},
        {path: 'simple', component: SimpleComponent},
        {path: 'mobile', component: MobileComponent},
        {path: 'modern', component: ModernComponent},
        {path: 'slider', component: SliderComponent},
        {path: 'waiter-display', component: WaiterDisplayComponent}
      ]
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignageRoutingModule {
}
