export class ApiOrderItemFeature {
  OnlineOrderItemFeatureID: number;
  OnlineOrderItemID: number;
  OnlineOrderID: number;
  ProductFeatureID: number;
  CustomerID: number;
  Countable: boolean;
  Name: string;
  Price: number;
  Value: number;
  Quantity: number;
  Status: number;
  Deleted: boolean;
  Description: string;

  constructor() {
    this.OnlineOrderItemFeatureID = null;
    this.OnlineOrderItemID = null;
    this.OnlineOrderID = null;
    this.ProductFeatureID = null;
    this.CustomerID = null;
    this.Countable = false;
    this.Name = "";
    this.Price = 0;
    this.Value = 0;
    this.Quantity = 1;
    this.Status = 0;
    this.Deleted = false;
    this.Description = "";
  }
}
