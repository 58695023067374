import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {ApiData} from '../../shared/model/api-data.model';
import {ApiService} from '../../shared/service/api.service';
import {CartService} from '../../shared/service/cart.service';
import {ApiOrderItem} from '../../shared/model/api.order-item.model';
import {ApiAccount} from '../../shared/model/api.account.model';
import {AppStorage, StorageKey} from '../../storage';
import {generateUniqueID, getAddressErrorMessage, Loading} from '../../utils';
import {PaymentType} from '../../shared/enum/payment-type.enum';
import {OrderParams, OrderService} from '../../shared/service/order.service';
import {LanguageService} from '../../shared/service/language.service';
import * as _ from 'lodash';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {DeliveryType} from '../../shared/enum/delivery-type.enum';
import {OrderStatus} from '../../shared/enum/order-status.enum';
import {ApiOnlinePayment} from '../../shared/model/api.online-payment.model';
import {PaymentStatus} from '../../shared/enum/payment-status.enum';
import {GeneralError} from '../../shared/enum/general-error.enum';
import {Bootbox} from '../../bootbox';
import {EnvironmentService} from '../../shared/service/environment.service';
import {Router} from '@angular/router';
import {timeout} from 'q';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
})
export class BasketComponent implements OnInit, AfterViewInit {
  cart: ApiOrderItem[] = [];
  apiData: ApiData;
  account: ApiAccount;

  securePaymentContent: any;
  shippingImage: any;
  country: any;
  onlinePaymentStatus: number = 0;
  onlinePaymentID = null;

  failMessage = '';
  customerName = '';
  customerAddress = '';
  customerPhone = '';
  customerEmail = '';

  paymentRequiredMode = 0;
  paymentType = PaymentType.Cash;
  paymentTypes = PaymentType;

  deliveryType = DeliveryType.Delivery;
  deliveryTypes = DeliveryType;

  currency: string = '';

  _cities: any;
  _streets: any;
  _districts: any;

  orderNote: string = '';

  profileName: string = '';
  profilePhone: string = '';
  profilePassword: string = '';
  profileCity: string = '-1';
  profileDistrict: string = '-1';
  profilePostcode: string = '';
  profileStreet: string = '';
  profileAddress: string = '';

  cardName: string = '';
  cardNumber: string = '';
  cardExpiration: string = '';
  cardCVC: string = '';

  appName: string = '';
  contactEmail: string = '';
  mersisNo: string = '';

  postCode: any;
  securePayment = false;

  hiddenDeliveryTypes = true;
  agreementAccepted = false;
  onlineOrderDisable: boolean;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private languageService: LanguageService,
    private titleService: Title,
    private orderService: OrderService,
    public cartService: CartService,
    public environmentService: EnvironmentService,
    private sanitizer: DomSanitizer) {

    // Assign storage account
    this.account = AppStorage.getItem(StorageKey.USER) as ApiAccount;
  }

  ngOnInit() {
    const that = this;
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.onlineOrderDisable = apiData.Settings.WebSiteOrderMode !== 0;

      this.appName = apiData.Title;
      this.contactEmail = apiData.Email;
      this.mersisNo = '';


      // TODO: Remove this as soon as possible!
      Loading.show()
        .then(() => this.apiService.getCustomerSummary())
        .then(data => {
          this.mersisNo = data['MersisNo'];
        })
        .then(() => {
          Loading.hide();
        });
      this.apiData = apiData;
      this.onShippingImage();
      this.country = this.apiData.Country;

      this.customerName = apiData.Title;
      this.customerEmail = apiData.Email;
      this.customerAddress = apiData.Address;
      this.customerPhone = apiData.Telephone;

      // Automatically select online payment if
      // payment is required.
      this.paymentRequiredMode = this.apiData.Settings.PaymentRequiredMode;

      if (this.paymentRequiredMode === 1) {
        this.paymentType = PaymentType.Online;
        this.onClickPaymentType(this.paymentType);
      }

      if (apiData.Settings.Locale === 'GB' && apiData.CustomerID !== 13252) {
        this.hiddenDeliveryTypes = false;
      }

      const translations = this.languageService.translations;
      this.titleService.setTitle(`${apiData.Title} | ${translations['basket.title']}`);

      this.cart = this.cartService.cart;

      this.cart.forEach(orderItem => {
        // Check ImageUrl is null
        if (orderItem.ImageUrl === null) {
          orderItem.ImageUrl = this.apiData.ImageUrl;
        }

        orderItem.OrderItemFeaturesString = '';
        if (typeof orderItem.OrderItemFeatures === 'undefined' || orderItem.OrderItemFeatures === null) {
          return;
        }

        orderItem.OrderItemFeaturesString = orderItem.OrderItemFeatures.map(i => i.Name).join(', ');
      });

      const user = AppStorage.getItem(StorageKey.USER) as ApiAccount;
      if (user === null) {
        return;
      }

      if (this.environmentService.environment.Locale !== 'TR') {
        this.agreementAccepted = true;
      }

      this.currency = this.environmentService.environment.Currency;

      Loading.show()
        .then(() => this.apiService.getCities(this.apiData.Settings.Locale))
        .then(cities => {
          this._cities = cities;
        })
        .then(() => this.apiService.getAccount(user.AccountID))
        .then((account: ApiAccount) => {
          that.account = account;

          // Set profile details
          this.profileName = account.Name;
          this.profilePhone = account.Cellphone;
          this.profilePassword = account.Address;
          this.profileCity = account.CityTown;
          this.profileDistrict = account.District;
          this.profilePostcode = account.PostCode;
          this.profileAddress = account.Address;
        })
        .then(() => this.apiService.getDistricts(this.apiData.Settings.Locale, this.profileCity))
        .then((data: []) => {
          if (data.length === 0) {
            this.profileDistrict = '-1';
            return;
          }

          this._districts = data;

          this.profileDistrict = this._districts.find(district => {
            return district.Name === this.profileDistrict;
          });

          if (typeof this.profileDistrict !== 'undefined') {
            this.profileDistrict = this.profileDistrict['Name'];
            return;
          }

          this.profileDistrict = '-1';

        })
        .finally(() => {
          Loading.hide();
        });
    });

    $('.modal').on('shown.bs.modal', function() {
      $(this).before($('.modal-backdrop'));
    });
  }

  onShippingImage() {
    const filteredShippingImage= this.apiData.Images.filter(i => i.Type === 0 && i.Category === 11);

    if (filteredShippingImage && filteredShippingImage.length > 0 && this.cartService.cart.length > 0) {
      this.shippingImage = filteredShippingImage[0].ImageUrl;

      // @ts-ignore
      $('#shippingChargesModal').modal('show');
    }
  }

  ngAfterViewInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      // Automatically select online payment if
      // payment is required.
      this.paymentRequiredMode = apiData.Settings.PaymentRequiredMode;

      if (this.paymentRequiredMode !== 1) {
        return;
      }

      this.paymentType = PaymentType.Online;
      this.onClickPaymentType(this.paymentType);
    });
  }

  onSelectedCityChanged() {
    this.apiService.getDistricts(this.apiData.Settings.Locale, this.profileCity)
      .then(data => {
        this.profileDistrict = '-1';
        this._districts = data;
      });
  }

  onClickPostCodeButton() {
    const translations = this.languageService.translations;
    Loading.show().then(() => {
      this.postCode = this.profilePostcode;
      if (this.postCode !== '') {

        this.apiService.getAdress('GB', this.postCode).then(data => {
          this._streets = data;

          if (this._streets.length <= 0) {
            Bootbox.alert(translations['signup.postcode_validation_error_c']);
            return;
          } else {
            this.profileCity = this._streets[0].Name;
            this.profileDistrict = this._streets[0].District;
          }
        });
      }
    })
      .finally(() => Loading.hide());
  }

  onSelectedStreetChanged(value) {
    this.profileStreet = value;
  }

  /**
   * Removes an item from cart and
   * updates view.
   * @param orderItem
   */
  onClickDeleteCartItem(orderItem: ApiOrderItem) {
    this.cartService.drop(orderItem);
  }

  onClickEmptyCartButton() {
    this.cartService.clear();
    this.cart = [];
  }

  onClickPaymentType(type: PaymentType) {
    this.paymentType = type;
  }

  onClickDeliveryType(type: DeliveryType) {
    this.deliveryType = type;
  }

  onClickProfileSaveChanges() {
    const translations = this.languageService.translations;
    const user = AppStorage.getItem(StorageKey.USER) as ApiAccount;

    //region Validation
    if (_.isEmpty(this.profileName)) {
      Bootbox.alert(translations['general.name_error_c']);
      return;
    }

    if (this.country === 'GBR') {
      if (_.isEmpty(this.profileStreet)) {
        Bootbox.alert(translations['general.street_error_c']);
        return;
      }
    }

    const addressBundle = {
      telephone: this.profilePhone,
      address: this.profileAddress,
      city: this.profileCity,
      district: this.profileDistrict,
      street: this.profileStreet,
      postcode: this.profilePostcode
    };

    const addressError = getAddressErrorMessage(
      addressBundle,
      translations
    );

    if (addressError != null) {
      Bootbox.alert(addressError);
      return;
    }
    //endregion

    const account = {
      AccountID: user.AccountID,
      CustomerID: ApiService.customerId,
      Name: this.profileName,
      Cellphone: this.profilePhone,
      Telephone: this.profilePhone,
      Phone: this.profilePhone,
      ImageUrl: null,
      CityTown: this.profileCity,
      District: this.profileDistrict,
      Street: this.profileStreet,
      Address: this.profileAddress,
      UserName: this.profilePhone,
      Password: null,
      PostCode: this.profilePostcode
    };

    Loading.show()
      .then(() => {
        if (this.country !== 'GBR') {
          return this.apiService.checkPostcode(this.apiData.Settings.Locale, this.profileCity, this.profileDistrict, this.profilePostcode);
        }
      })
      .then(data => {
        if (data === 0) {
          throw 0;
        }
      })
      .then(() => this.apiService.putAccount(account))
      .then((data) => {
        if (!data.hasOwnProperty('ID')) {
          return;
        }

        return this.apiService.getAccount(data['ID']);
      })
      .then((data) => {
        AppStorage.setItem(StorageKey.USER, data);

        Bootbox.alert(translations['profile_details.account_info.success_profile_update']);

        // @ts-ignore
        $('#profileAddress').modal('hide');
      })
      .catch((error) => {
        if (error === 0) {
          Bootbox.alert(translations['profile_details.account_info.postcode_validation_error_c']);
          return;
        }

        Bootbox.alert(translations['profile_details.account_info.profile_update_error_c']);
      })
      .finally(() => {
        Loading.hide();
      });
  }

  onClickCompleteOrder() {
    const checkPaymentMode = this.apiData.Settings.OnlinePaymentMode;
    if (checkPaymentMode !== 77 && this.paymentType === this.paymentTypes.Online && (this.cardCVC === '' || this.cardExpiration === '' || this.cardNumber === '' || this.cardName === '')) {
      $('#paymentForm').addClass('border-for-orders-selected').css('position', 'relative');

      // Shake effect
      $('#paymentForm').animate({left: '-=10px'}, 50)
        .animate({left: '+=20px'}, 50)
        .animate({left: '-=20px'}, 50)
        .animate({left: '+=20px'}, 50)
        .animate({left: '-=10px'}, 50);

      return;
    }


    const translations = this.languageService.translations;
    if (this.cartService.cart.length === 0) {
      Bootbox.alert(translations['basket.cart_empty']);
      return;
    }

    if (!this.agreementAccepted) {
      Bootbox.alert('Mesafeli Satış Sözleşmesini onaylamanız gerekiyor.');
      return;
    }

    const menuType = this.apiData.Menu.Category;
    const options = new OrderParams();

    if (menuType === 12) {
      options.orderType = menuType;
    }

    if (menuType === 11) {
      options.orderType = menuType;
    }

    options.orderStatus = OrderStatus.NEW;
    options.accountId = this.account.AccountID;
    options.paymentType = this.paymentType;
    options.description = this.orderNote;

    if (this.apiData.CustomerID === 13235 || this.apiData.CustomerID === 13257) {
      options.deliveryType = 10;
    } else {
      options.deliveryType = this.deliveryType;
    }

    options.uniqueId = generateUniqueID();

    // Check if online payment is required
    if (this.isPaymentRequired()) {
      options.paymentType = this.apiData.Settings.OnlinePaymentMode;

      options.paid = false;
      options.orderStatus = OrderStatus.WAITING;
    }

    function isOrderAvailable(result) {
      const currentDate = new Date();
      const currentDay = currentDate.toLocaleString('en-US', {weekday: 'long'});
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      const orderDays = JSON.parse(result.RestaurantOrderDays);
      const orderHours = result.RestaurantOrderHours;

      if (Object.values(orderDays).every(day => day === 0)) {
        return true;
      }

      if (orderDays[currentDay] === 1) {
        const hourRanges = orderHours.split(',');
        for (const hourRange of hourRanges) {
          const [start, end] = hourRange.split('-').map(time => {
            if (time === '00:00') {
              return 24 * 60;
            }
            const [hour, minute] = time.split(':').map(val => parseInt(val, 10));
            return hour * 60 + minute;
          });

          const currentMinuteOfDay = currentHour * 60 + currentMinute;

          if (start === 24 * 60 && end === 24 * 60) {
            return true;
          }

          if (currentMinuteOfDay >= start && currentMinuteOfDay < end) {
            return true;
          }
        }
      }
      return false;
    }

    this.apiService.getPackageSettings(this.apiData.CustomerID)
      .then(result => {
        const isAvailable = isOrderAvailable(result);
        if (isAvailable) {
          Loading.show()
            .then(() => this.orderService.setOrder(options))
            .then(() => this.orderService.postOrder())
            .then((value: Object) => {
              // Clear order note
              this.orderNote = '';

              const orderId = value['ID'];
              this.orderService.order.OrderID = orderId;
              this.orderService.order.OnlineOrderID = orderId;

              if (options.orderStatus === OrderStatus.WAITING && this.paymentType == PaymentType.Online) {
                if (this.country === 'GBR') {
                  this.processPaykukiPayment();
                } else {
                  this.processPayment();
                }
                return;
              }

              this.onOrderCompleted();

              Loading.hide();
            })
            .catch(error => {
              Loading.hide();

              if (error.hasOwnProperty('status') && error.status === 503) {
                Bootbox.alert(translations['basket.restaurant_unavailable']);
                return;
              }

              if (error.hasOwnProperty('status') && error.status === 412) {
                Bootbox.alert(translations['basket.restaurant_not_serve_postcode']);
                return;
              }
            });
        } else {
          // @ts-ignore
          $('#paymentFailedPopup').modal('show');
        }
      });
  }

  onClickDecreaseQuantity(orderItem: ApiOrderItem) {
    this.cartService.decrement(orderItem);
  };

  onClickIncreaseQuantity(orderItem: ApiOrderItem) {
    this.cartService.increment(orderItem);
  };

  on3DsPaymentRequired(data: ApiResponseObject) {
    const that = this;

    return new Promise((resolve, reject) => {
      const paymentId = data.ID;
      const result = JSON.parse(data.Result);

      // Show iframe modal
      const paymentModal = $('#3dsPaymentModal');

      // @ts-ignore
      paymentModal.modal('show');

      // Set form details...
      const form = $('#3dsPaymentForm');
      form.attr('action', result['threeDSACSURL']);

      form.find('#MD').val(result['threeDSMD']);
      form.find('#PaReq').val(result['threeDSPaReq']);
      form.find('#TermUrl').val(result['threeDSRedirectURL']);

      // Post the form inside the iframe
      // noinspection JSDeprecatedSymbols
      form.submit();

      let shouldCheckPaymentStatus = true;

      /**
       * Starts a thread that checks status of a
       * payment until it switches to a decent state.
       */
      (function checkPaymentStatus() {

        /**
         * Re-checks the status of a payment
         * after 1 second.
         */
        function retry() {
          if (!shouldCheckPaymentStatus) {
            return;
          }

          setTimeout(() => checkPaymentStatus(), 1000);
        }

        that.apiService.getOnlinePayment(paymentId)
          .then((payment: ApiOnlinePayment) => {

            if (payment.Status === PaymentStatus.WAITING) {
              retry();
              return;
            }

            // 3Ds Authentication is failed.
            if (payment.Status === PaymentStatus.NOT_ACCEPTED) {
              reject(GeneralError.PAYMENT_NOT_ACCEPTED);
            }

            //
            if (payment.Status === PaymentStatus.ACCEPTED) {
              resolve(payment);

              // noinspection JSIgnoredPromiseFromCall
              that.onPaymentCompleted();
            }

            // @ts-ignore
            paymentModal.modal('hide');
            shouldCheckPaymentStatus = false;
          })
          .catch(e => {
            console.error('on3DsPaymentRequired:', e);
            retry();
          });
      })();
    });
  };

  onPaymentCompleted() {
    this.orderService.order.Paid = true;
    this.orderService.order.Status = OrderStatus.NEW;

    return this.orderService.putOrder();
  }

  /**
   * Gets called when an order is sent
   * successfully.
   */
  onOrderCompleted() {

    // Show success pop-up
    // @ts-ignore
    $('#succesfulOrderPopup').modal('show');

    // Clear cart
    this.cartService.clear();

    // Clear card information area
    this.cardName = '';
    this.cardNumber = '';
    this.cardCVC = '';
  }

  isPaymentRequired() {
    return this.paymentType === PaymentType.Online;
  }

  //onCloseButton() {
  //  this.apiService.getOnlinePaymentStatus(this.onlinePaymentID)
  //    .then((result) => {
  //      if (result) {
  //        this.onlinePaymentStatus = result;
  //        if (this.onlinePaymentStatus === 0) {
  //          alert('ödeme işlemi henüz gerçekleşmedi');
  //        }
//
  //        if (this.onlinePaymentStatus === 1) {
  //          // @ts-ignore
  //          $('#securePaymentModal').modal('hide');
  //          this.onPaymentCompleted();
  //          this.onOrderCompleted();
  //        }
//
  //        if (this.onlinePaymentStatus === -1) {
  //          // @ts-ignore
  //          $('#securePaymentModal').modal('hide');
  //        }
  //      }
  //    });
  //}

  processPayment() {
    // Create a new online payment
    const onlinePayment = new ApiOnlinePayment();
    onlinePayment.Type = this.apiData.Settings.OnlinePaymentMode;

    onlinePayment.CardHolderName = this.cardName;
    onlinePayment.CardNumber = this.cardNumber;
    onlinePayment.CardCVC = this.cardCVC;

    const expire = this.cardExpiration.split('/');
    onlinePayment.CardMonth = expire[0];
    onlinePayment.CardYear = expire[1];

    // Set order details
    const order = this.orderService.order;
    onlinePayment.OnlineOrderID = order.OnlineOrderID;
    onlinePayment.Price = order.TotalPrice;

    // Set account ID
    onlinePayment.AccountID = order.AccountID;

    // Set customer ID
    onlinePayment.CustomerID = order.CustomerID;

    if (this.securePayment === true) {
      Loading.show()
        .then(() => this.apiService.postOnlinePayment3D(onlinePayment))
        .then((data: any) => {
          if (data) {
            this.onlinePaymentID = data.OnlinePaymentID;
            const blob = new Blob([data.Content], {type: 'text/html'});
            const obj = URL.createObjectURL(blob);
            this.securePaymentContent = this.sanitizer.bypassSecurityTrustResourceUrl(obj);
            this.onSuccessfulPayment();

            // @ts-ignore
            $('#securePaymentModal').modal('show');
          } else {
            // @ts-ignore
            $('#paymentFailedPopup').modal('show');
          }
        })
        .catch((err) => {
          this.failMessage = err.responseText;
          // @ts-ignore
          $('#paymentFailedPopup').modal('show');
        })
        .finally(() => {
          Loading.hide();
        });
    } else {
      Loading.show()
        .then(() => this.apiService.postOnlinePayment(onlinePayment))
        .then((data: ApiResponseObject) => {
          if (data.Status === 150) {
            Loading.hide();
            return this.on3DsPaymentRequired(data);
          }

          return this.onPaymentCompleted();
        })
        .then(() => this.onOrderCompleted())
        .catch(() => {
          // @ts-ignore
          $('#paymentFailedPopup').modal('show');
        })
        .finally(() => {
          Loading.hide();
        });
    }
  }

  onSuccessfulPayment() {
    const interval = setInterval(() => {
      this.apiService.getOnlinePaymentStatus(this.onlinePaymentID)
        .then((result) => {
          if (result === 1) {
            // @ts-ignore
            $('#securePaymentModal').modal('hide');
            this.onPaymentCompleted();
            this.onOrderCompleted();
            clearInterval(interval); // interval'i durdur
          } else if (result === -1) {
            // Başarısız ödeme durumunda interval'i durdur
            clearInterval(interval);
          }
        });
    }, 3000); // 3 saniyede bir API isteği yap
  }

  processPaykukiPayment() {
    // Create a new online payment
    const onlinePayment = new ApiOnlinePayment();
    onlinePayment.Type = this.apiData.Settings.OnlinePaymentMode;

    onlinePayment.CardHolderName = '';
    onlinePayment.CardNumber = '';
    onlinePayment.CardCVC = '';

    const expire = '';
    onlinePayment.CardMonth = '';
    onlinePayment.CardYear = '';

    // Set order details
    const order = this.orderService.order;
    onlinePayment.OnlineOrderID = order.OnlineOrderID;
    onlinePayment.Price = order.TotalPrice;

    // Set account ID
    onlinePayment.AccountID = order.AccountID;

    // Set customer ID
    onlinePayment.CustomerID = order.CustomerID;

    Loading.show()
      .then(() => this.apiService.postOnlinePayment(onlinePayment))
      .then((data: ApiResponseObject) => {
        if (!data.hasOwnProperty('Result')) {
          return;
        }

        data.Result = JSON.parse(data.Result);
        const checkoutUrl = data.Result['CheckoutUrl'];
        window.open(checkoutUrl, '_blank').focus();

        // Clear cart
        this.cartService.clear();
        AppStorage.setItem('paykukiPayment', true);
        AppStorage.setItem('PaymentID', data.ID);
        Loading.hide();
        this.router.navigate(['profile-details']);
      })
      .catch(() => {
        // @ts-ignore
        $('#paymentFailedPopup').modal('show');
      })
      .finally(() => {
        Loading.hide();
      });
  }

  cardNumberKeydown(event: KeyboardEvent) {
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      // Sadece rakamları ve boşlukları kabul et
      if (!/^\d$/.test(event.key) && event.key !== ' ') {
        event.preventDefault();
      }
      // Kart numarasında her dört karakterde bir boşluk bırak
      if (this.cardNumber.length === 4 || this.cardNumber.length === 9 || this.cardNumber.length === 14) {
        this.cardNumber += ' ';
      }
    }
  }

  cardExpireKeydown(event: KeyboardEvent) {
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      // Sadece rakamları ve '/' karakterini kabul et
      if (!/^\d$/.test(event.key) && event.key !== '/') {
        event.preventDefault();
      }
      // Son kullanma tarihinde her iki karakterde bir '/' ekle
      if (this.cardExpiration.length === 2) {
        this.cardExpiration += '/';
      }
    }
  }
}
