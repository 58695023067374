import {ApiMenuGroup} from '../../shared/model/api-menu-group.model';

/**
 * Eski website projesinden alınmıştır. Menü gruplarını tam sayfa menü tasarımına uygun boyutlu parçalara ayırır.
 * @param menuGroups Tüm menü gruplarını içeren dizi
 * @returns İlk indeks tasarımdaki sütunları temsil eder, ikinci indekste sütundaki menü grupları bulunur.
 */
export function parseMenuGroups(menuGroups: ApiMenuGroup[]): ApiMenuGroup[][] {
  const colLimit = 40;
  const hdrVol = 4;
  const rowVol = 2;
  const colCount = 4;

  let newMenuGroup: {};
  const newMenuGroups = [];

  let volIndex = 0;

  let offsetVol;

  Object.keys(menuGroups).forEach(i => {
    if ((volIndex + (menuGroups[i].Products.length * rowVol) + hdrVol) <= colLimit) {
      newMenuGroup = {
        Name: menuGroups[i].Name,
        ImageUrl: menuGroups[i].ImageUrl,
        Products: menuGroups[i].Products
      };
      newMenuGroups.push(newMenuGroup);
      volIndex = volIndex + (menuGroups[i].Products.length * rowVol) + hdrVol;
    } else {
      offsetVol = volIndex + hdrVol;
      // Products count, Kaç parçaya bölünecek?
      const pc = Math.ceil(((menuGroups[i].Products.length * rowVol) + hdrVol + volIndex) / colLimit);
      let jjj = 0;
      for (let p = 0; p < pc; p++) {
        const newProducts = [];
        let jj = 0;

        for (let j = offsetVol; j < colLimit && (jjj) < menuGroups[i].Products.length; j = j + rowVol) {
          newProducts[jj] = menuGroups[i].Products[jjj]; // (j - offsetVol) / rowVol
          jj = jj + 1;
          jjj = jjj + 1;
        }
        if (newProducts.length > 0) {
          newMenuGroup = {
            Name: menuGroups[i].Name,
            ImageUrl: menuGroups[i].ImageUrl,
            Products: newProducts
          };
          newMenuGroups.push(newMenuGroup);
        }
        volIndex = volIndex + ((newMenuGroup as any).Products.length * rowVol) + hdrVol;
        offsetVol = hdrVol;
      }
    }

    volIndex = volIndex % colLimit;
  });

  const CLX = [];
  let clxMenu = [];

  volIndex = 0;
  let k = 0;

  for (let j = 0; j < colCount; j++) {
    clxMenu = [];
    for (let iM = k; iM < newMenuGroups.length; iM++) {
      if ((volIndex + (newMenuGroups[iM].Products.length * rowVol + hdrVol)) > colLimit) {
        k = iM;
        break;
      }

      clxMenu.push(newMenuGroups[iM]);
      volIndex = volIndex + (newMenuGroups[iM].Products.length * rowVol + hdrVol);
      if (iM + 1 === newMenuGroups.length) {
        k = newMenuGroups.length;
      }
    }

    CLX.push(clxMenu);
    volIndex = 0;
  }

  return CLX;
}
