import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {ApiProduct} from '../../shared/model/api-product.model';
import {SignageImageCategory} from '../../shared/enum/image-category.enum';
import {getImageUrl, setMissingImageUrls} from '../../api-utils';
import {getArrayGrid} from '../../utils';
import {ApiDataService} from '../../shared/service/api-data.service';

@Component({
  selector: 'app-modern',
  templateUrl: './modern.component.html',
  styleUrls: ['./modern.component.scss']
})
export class ModernComponent implements OnInit {
  private static readonly NUM_ROWS = 4;
  private static readonly NUM_COLS = 2;

  pageImageUrl: string;
  productGrid: ApiProduct[][][];

  constructor(private apiService: ApiService, private apiDataService: ApiDataService) {
  }

  ngOnInit() {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiDataService.apiData = apiData;

      this.pageImageUrl = getImageUrl(this.apiDataService.getSignagePageImage(SignageImageCategory.Modern)) ||
        '/assets/images/page_backgrounds/modern.jpg';

      const products = this.apiDataService.getSignageProducts();
      setMissingImageUrls(products, apiData.ImageUrl);
      this.productGrid = getArrayGrid(products, ModernComponent.NUM_ROWS, ModernComponent.NUM_COLS, true);
    });
  }

}
