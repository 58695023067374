import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';

export const EVENT_NAMES = {
  OnLoggedIn: 'OnLoggedIn',
  OnPageTitleChanged: 'OnPageTitleChanged'
};

@Injectable()
export class EventBroadcasterService {
  private readonly subjects: Map<string, Subject<any>>;

  constructor() {
    this.subjects = new Map<string, Subject<any>>();

    for (let key in EVENT_NAMES) {
      this.register(EVENT_NAMES[key]);
    }
  }

  /**
   * Registers a new event
   * to be registered.
   * @param eventname
   */
  private register(eventname: string) {
    this.subjects.set(eventname, new Subject<any>());
  }

  /***
   * Triggers an event with given
   * data
   * @param eventname
   * @param data
   */
  trigger(eventname: string, data: any) {
    const subject = this.subjects.get(eventname);
    subject.next(data);
  }

  get(eventname: string): Observable<any> {
    return this.subjects.get(eventname).asObservable();
  }
}
