export class ApiOnlinePayment {
  CustomerID: number;
  AccountID: number;
  GuestID: number;
  DeviceID: number;
  OnlineOrderID: number;
  TableID: number;
  SessionID: number;
  SessionLID: number;
  Price: number;
  Currency: string;
  IpAddress: string;
  Locale: string;
  CardHolderName: string;
  CardNumber: string;
  CardMonth: string;
  CardYear: string;
  CardCVC: string;
  CardUserKey: string;
  CardToken: string;
  RegisterCard: boolean;
  Type: number;
  Status: number;
  Description: string;
}
