import {AfterViewInit, Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ApiService} from './shared/service/api.service';
import {ApiFunctionName} from './shared/enum/api-function-name.enum';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {ModuleUtils} from './module-utils';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from './shared/service/language.service';
import {websiteRoutes} from './website/website-routing.module';
import {ApiData} from './shared/model/api-data.model';
import {GAService} from './shared/service/ga.service';
import {EnvironmentService} from './shared/service/environment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('preloader', {static: false}) preloader;
  isWebsite: boolean;

  constructor(private apiService: ApiService,
              private languageService: LanguageService,
              private gaService: GAService,
              private environmentService: EnvironmentService,
              private router: Router,
              private translate: TranslateService,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(e => {
      if (e instanceof RoutesRecognized) {
        const url = e.urlAfterRedirects;

        // Try to find path in website routes
        this.isWebsite = websiteRoutes
          .filter(i => i.path.indexOf(url.substring(1, url.lastIndexOf('/'))) !== -1)
          .length > 0;

        if (!this.isWebsite) {
          ModuleUtils.addSignageClasses(this.renderer);
          return;
        }

        ModuleUtils.removeSignageClasses(this.renderer);
      }
    });
  }

  ngAfterViewInit(): void {
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe((apiData: ApiData) => {
      this.handleLanguage(apiData);

      this.environmentService.environment = {
        CustomerID: apiData.CustomerID,
        Locale: apiData.Settings.Locale,
        Currency: apiData.Menu.Currency,
      };

      this.preloader.nativeElement.remove();
    });
  }

  private handleLanguage(data) {
    this.languageService.translate = this.translate;

    if (data.Languages != null) {
      data.Languages.forEach(language => {
        this.languageService.addLanguage(language);
      });
    }

    this.languageService.checkSetLanguage();
  }
}
