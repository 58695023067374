import {ApiOrderItemFeature} from './api.order-item-feature.model';
import * as _ from 'lodash';

export class ApiOrderItem {
  OrderItemID: number;
  OrderID: number;
  OnlineOrderItemID: number;
  OnlineOrderID: number;
  MenuID: number;
  CustomerID: number;
  ProductID: number;
  Code: string;
  Name: string;
  Comment: string;
  ImageUrl: string;
  Quantity: number;
  Price: number;
  FeatureValue: number;
  FeaturePrice: number;
  TaxPrice: number;
  UnitPrice: number;
  TotalPrice: number;
  Tax: number;
  OrderTime: string;
  Status: number;
  Type: number;
  Group: number;
  Integrated: boolean;
  Returned: boolean;
  Gifted: boolean;
  Cancelled: boolean;
  Closed: boolean;
  Deleted: boolean;
  Currency: string;
  Description: string;

  OrderItemFeatures: ApiOrderItemFeature[];
  OrderItemFeaturesString: string;

  constructor(orderItem?) {
    this.OrderItemID = null;
    this.OrderID = null;
    this.OnlineOrderItemID = null;
    this.OnlineOrderID = null;
    this.MenuID = null;
    this.CustomerID = null;
    this.ProductID = null;
    this.Code = null;
    this.Name = null;
    this.Comment = null;
    this.ImageUrl = null;
    this.Quantity = 1;
    this.Price = 0;
    this.FeatureValue = 0;
    this.FeaturePrice = 0;
    this.TaxPrice = 0;
    this.UnitPrice = 0;
    this.TotalPrice = 0;
    this.Tax = 0;
    this.OrderTime = null;
    this.Status = 0;
    this.Type = 0;
    this.Group = null;
    this.Integrated = false;
    this.Returned = false;
    this.Gifted = false;
    this.Cancelled = false;
    this.Closed = false;
    this.Deleted = false;
    this.Currency = "";
    this.Description = "";

    // Copy properties of order item
    // if provided.
    if (orderItem) {
      Object.assign(this, orderItem);
    }
  }

  /**
   * Checks order item against other one
   * if features are equal.
   * @param other
   */
  public areFeaturesEqual(other: ApiOrderItem) {
    const features1 = this.OrderItemFeatures || [];
    const features2 = other.OrderItemFeatures || [];

    const length1 = features1.length;
    const length2 = features2.length;

    // If both items have no features then assume
    // that they are equal. This should be carefully
    // handled as this function itself does not
    // checks if order items are the same.
    if (length1 === 0 && length2 === 0) {
      return true;
    }

    if (length1 === length2) {
      // Map feature IDs of both order items.
      let featureIds1 = features1.map(feature => feature.ProductFeatureID).sort();
      let featureIds2 = features2.map(feature => feature.ProductFeatureID).sort();

      // If two feature IDs are equally sorted then
      // assume that they are the same.
      if (_.isEqual(featureIds1, featureIds2)) {
        return true;
      }
    }

    // If no condition is met then they are not
    // equal.
    return false;
  }

  /**
   * Remove countable features from
   * Features list
   */
  public removeCountableFeatures() {
    if (typeof this.OrderItemFeatures === 'undefined' || this.OrderItemFeatures === null)
      return;

    _.pullAll(
      this.OrderItemFeatures,
      this.OrderItemFeatures.filter(
        (feature) => feature.Countable
      )
    )
  }
}
