export const jsAirport = (function () {
  return {
    setData: function (row, items, change_time, page) {
      var e = 0;
      var f = page;

      jsAirport.setDataItem(row, page, items, e, f);

      setInterval(function () {
        e = f,
          e >= items.length ? (e = 0,
            f = page) : (f = e + page,
          f > items.length && (f = items.length)),
          jsAirport.setDataItem(row, page, items, e, f);
      }, change_time);
    },

    setDataItem: function (row, page, items, d, e) {
      var idx;
      var current_page = 1;
      var data = items.slice(d, e);

      for (idx in data) {
        if (!(current_page <= page)) {
          break;
        }

        $(row).find(".flap" + current_page).airport(data[idx]);
        current_page++;
      }
    },
  };
}());
