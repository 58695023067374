import {flagArray} from "./toFrom";
import {jsAirport} from "./monteair";
import {selectRandFromArray} from "../../../../utils";

const cell_cache = [];
const column_cache = [];

export function initAirport(products) {
  $.fn.extend({
    initAirport: function () {
      var flapDiv = $(this);
      var page = 11;
      var change_time = 30000;

      $("#have-a-good-flight").addClass('full-screen-element');

      for (var row = 1; row <= page; row++) {
        $(flapDiv).append('<div class="split-flap flap' + row + '" data-row-id="' + row + '">\n' +
          '<div class="row">\n' +
          '  <div class="col-md-2"><span class="dst col0"></span></div>\n' +
          '  <div class="col-md-6"><span class="dst col1"></span></div>\n' +
          '  <div class="col-md-2"><span class="fee col2"></span></div>\n' +
          '  <div class="col-md-2">' +
          '  <span class="flag-icon flag-icon-tr col3"></span>\n' +
          '  <span class="tofrom col4"></span></div>\n' +
          "</div>\n" +
          "</div>");

        var obj = flapDiv.find(".flap" + row);

        for (var col = 0; col < 31; col++) {
          if (col < 5) {
            $(obj).find(".col0").append("<span data-cell-id='" + col + "' class='stats-flipper-number c" + col + "'>&nbsp;</span>");
          } else if (col < 22) {
            $(obj).find(".col1").append("<span data-cell-id='" + col + "' class='stats-flipper-number c" + (col - 5) + "'>&nbsp;</span>");
          } else if (col < 28) {
            $(obj).find(".col2").append("<span data-cell-id='" + col + "' class='stats-flipper-number c" + (col - 22) + "'>&nbsp;</span>");
          } else {
            $(obj).find(".col4").append("<span data-cell-id='" + col + "' class='stats-flipper-number c" + (col - 28) + "'>&nbsp;</span>");
          }
        }
      }

      for (var i = 0; i <= page; i++) {
        cell_cache[i] = [];
        column_cache[i] = [];

        for (var j = 0; j < 5; j++) {
          cell_cache[i][j] = [];
          column_cache[i][j] = $(".flap" + i + " .col" + j);

          for (var k = 0; k < 17; k++) {
            cell_cache[i][j][k] = $(".flap" + i + " .col" + j + " .c" + k).get(0);
          }
        }
      }

      jsAirport.setData(flapDiv, products, change_time, page);
    },

    airport: function (item) {
      var row = $(this);
      var letters = ["a", "b", "c", "ç", "d", "e", "f", "g", "ğ", "h", "ı", "i", "j", "k", "l", "m", "n", "o", "ö", "p", "q", "r", "s", "ş", "t", "u", "ü", "v", "w", "x", "y", "z"];
      var numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      var code = item.Code == null || item.Code === "" ? "00000" : item.Code;
      var name = (item.Name.length > 15 ? item.Name.substring(0, 15) + ".." : item.Name).split("");
      var flag = selectRandFromArray(flagArray, 1)[0];
      var to = flag.fromcode.split("");
      var price = item.Price.toFixed(2).toString() + "₺";

      var current_row_id = parseInt(row.attr("data-row-id"));

      if (price.length === 5) {
        price = " " + price;
      }

      price = price.split("");

      Start(current_row_id, 0, 0);
      Start(current_row_id, 1, 0);
      Start(current_row_id, 2, 0);
      Start(current_row_id, 3, 0);
      Start(current_row_id, 4, 0);

      function Start(current_row_id, col_idx, cell_idx) {
        if (col_idx === 0) {
          Animate(current_row_id, col_idx, cell_idx, 0, code[cell_idx], code.length, 5);
        } else if (col_idx === 1) {
          Animate(current_row_id, col_idx, cell_idx, 0, name[cell_idx], name.length, 17);
        } else if (col_idx === 2) {
          Animate(current_row_id, col_idx, cell_idx, 0, price[cell_idx], price.length, 6);
        } else if (col_idx === 3) {
          Animate(current_row_id, col_idx, cell_idx, 0, flag.country, 0, 0);
        } else if (col_idx === 4) {
          Animate(current_row_id, col_idx, cell_idx, 0, to[cell_idx], to.length, 3);
        }
      }

      function Animate(current_row_id, col_idx, cell_idx, step, text, text_length, total_column_count) {
        var current_column = column_cache[current_row_id][col_idx];

        setTimeout(function () {
          if (col_idx === 3 && step === 0) {
            current_column.attr("class", "col" + col_idx + " flag-icon flag-icon-" + flagArray[Math.round(304 * Math.random())].country);
          } else if (col_idx !== 3) {
            if (text === "₺" || text === "." || text === " ") {
              step = 5;
            } else {
              var c;

              if (col_idx === 2) {
                c = numbers[Math.round(9 * Math.random())];
              } else {
                c = letters[Math.round(30 * Math.random())];
              }

              // avoid errors
              if (typeof c === "undefined") {
                console.log("undefined c ");
              }

              if (typeof cell_cache[current_row_id][col_idx][cell_idx] === "undefined") {
                console.log("Undefined cell cache: current_row_id: ", current_row_id, "col_idx:", col_idx, "cell_idx: ", cell_idx);
              } else {
                cell_cache[current_row_id][col_idx][cell_idx].innerHTML = c;
              }
            }
          }

          // Animation Step is done.
          if (step === 5) {
            if (col_idx !== 3) {
              if (typeof cell_cache[current_row_id][col_idx][cell_idx] !== "undefined") {
                cell_cache[current_row_id][col_idx][cell_idx].innerHTML = text === " " ? "&nbsp;" : text;
              }
              if (text_length > cell_idx + 1) {
                Start(current_row_id, col_idx, cell_idx + 1);
              } else {
                var n;
                for (n = cell_idx + 1; n < total_column_count; n++) {
                  cell_cache[current_row_id][col_idx][n].innerHTML = "&nbsp;";
                }
              }
            }
          } else {
            Animate(current_row_id, col_idx, cell_idx, step + 1, text, text_length, total_column_count);
          }
        }, 30);
      }
    },

    oclock: function () {
      var oclock = $(this);

      oclock.html("<span class='stats-flipper-number hour'>0</span>" +
        "<span class='stats-flipper-number hour2'>0</span>" +
        "<span class='stats-flipper-number'>:</span>" +
        "<span class='stats-flipper-number minute'>0</span>" +
        "<span class='stats-flipper-number minute2'>0</span>" +
        "<span class='stats-flipper-number'>:</span>" +
        "<span class='stats-flipper-number second'>0</span>" +
        "<span class='stats-flipper-number second2'>0</span>");

      var hour1 = oclock.find(".hour");
      var hour2 = oclock.find(".hour2");
      var minute = oclock.find(".minute");
      var minute2 = oclock.find(".minute2");
      var second = oclock.find(".second");
      var second2 = oclock.find(".second2");

      function change_time() {
        var date = new Date();
        var h = ((date.getHours() < 10 ? "0" : "") + date.getHours()).toString().split("");
        var m = ((date.getMinutes() < 10 ? "0" : "") + date.getMinutes()).toString().split("");
        var s = ((date.getSeconds() < 10 ? "0" : "") + date.getSeconds()).toString().split("");

        hour1.html(h[0]);
        hour2.html(h[1]);
        minute.html(m[0]);
        minute2.html(m[1]);
        second.html(s[0]);
        second2.html(s[1]);

        requestAnimationFrame(change_time);
      }

      requestAnimationFrame(change_time);
    },
  });
}
