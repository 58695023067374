export const flagArray = [{
  country: "dk",
  fromcode: "AAL",
}, {
  country: "ci",
  fromcode: "ABJ",
}, {
  country: "ae",
  fromcode: "AUH",
}, {
  country: "ng",
  fromcode: "ABV",
}, {
  country: "gh",
  fromcode: "ACC",
}, {
  country: "tr",
  fromcode: "ADA",
}, {
  country: "et",
  fromcode: "ADD",
}, {
  country: "ye",
  fromcode: "ADE",
}, {
  country: "tr",
  fromcode: "ADF",
}, {
  country: "ru",
  fromcode: "AER",
}, {
  country: "tr",
  fromcode: "AJI",
}, {
  country: "ir",
  fromcode: "AWZ",
}, {
  country: "sy",
  fromcode: "ALP",
}, {
  country: "eg",
  fromcode: "HBE",
}, {
  country: "dz",
  fromcode: "ALG",
}, {
  country: "kz",
  fromcode: "ALA",
}, {
  country: "jo",
  fromcode: "AMM",
}, {
  country: "nl",
  fromcode: "AMS",
}, {
  country: "tr",
  fromcode: "ESB",
}, {
  country: "tr",
  fromcode: "AYT",
}, {
  country: "mg",
  fromcode: "TNR",
}, {
  country: "jo",
  fromcode: "AQJ",
}, {
  country: "tm",
  fromcode: "ASB",
}, {
  country: "er",
  fromcode: "ASM",
}, {
  country: "kz",
  fromcode: "TSE",
}, {
  country: "ru",
  fromcode: "ASF",
}, {
  country: "gr",
  fromcode: "ATH",
}, {
  country: "us",
  fromcode: "ATL",
}, {
  country: "la",
  fromcode: "AOU",
}, {
  country: "iq",
  fromcode: "SDA",
}, {
  country: "ml",
  fromcode: "BKO",
}, {
  country: "th",
  fromcode: "BKK",
}, {
  country: "es",
  fromcode: "BCN",
}, {
  country: "it",
  fromcode: "BRI",
}, {
  country: "ch",
  fromcode: "BSL",
}, {
  country: "iq",
  fromcode: "BSR",
}, {
  country: "tr",
  fromcode: "BAL",
}, {
  country: "dz",
  fromcode: "BLJ",
}, {
  country: "ge",
  fromcode: "BUS",
}, {
  country: "cn",
  fromcode: "PEK",
}, {
  country: "lb",
  fromcode: "BEY",
}, {
  country: "cz",
  fromcode: "BEG",
}, {
  country: "ly",
  fromcode: "BEN",
}, {
  country: "de",
  fromcode: "SXF",
}, {
  country: "de",
  fromcode: "TXL",
}, {
  country: "de",
  fromcode: "FMO",
}, {
  country: "es",
  fromcode: "BIO",
}, {
  country: "dk",
  fromcode: "BLL",
}, {
  country: "gb",
  fromcode: "BHX",
}, {
  country: "kg",
  fromcode: "FRU",
}, {
  country: "tr",
  fromcode: "BJV",
}, {
  country: "co",
  fromcode: "BOG",
}, {
  country: "it",
  fromcode: "BLQ",
}, {
  country: "ci",
  fromcode: "BGG",
}, {
  country: "fr",
  fromcode: "BOD",
}, {
  country: "us",
  fromcode: "BOS",
}, {
  country: "de",
  fromcode: "BRE",
}, {
  country: "be",
  fromcode: "BRU",
}, {
  country: "ro",
  fromcode: "OTP",
}, {
  country: "hu",
  fromcode: "BUD",
}, {
  country: "ar",
  fromcode: "EZE",
}, {
  country: "sa",
  fromcode: "ELQ",
}, {
  country: "tr",
  fromcode: "YEI",
}, {
  country: "eg",
  fromcode: "CAI",
}, {
  country: "tr",
  fromcode: "CKZ",
}, {
  country: "za",
  fromcode: "CPT",
}, {
  country: "ma",
  fromcode: "CMN",
}, {
  country: "it",
  fromcode: "CTA",
}, {
  country: "in",
  fromcode: "MAA",
}, {
  country: "us",
  fromcode: "ORD",
}, {
  country: "md",
  fromcode: "KIV",
}, {
  country: "ro",
  fromcode: "CLJ",
}, {
  country: "de",
  fromcode: "CGN",
}, {
  country: "lk",
  fromcode: "CMB",
}, {
  country: "gn",
  fromcode: "CKY",
}, {
  country: "ro",
  fromcode: "CND",
}, {
  country: "dz",
  fromcode: "CZL",
}, {
  country: "dk",
  fromcode: "CPH",
}, {
  country: "bj",
  fromcode: "COO",
}, {
  country: "sn",
  fromcode: "DKR",
}, {
  country: "tr",
  fromcode: "DLM",
}, {
  country: "sy",
  fromcode: "DAM",
}, {
  country: "sa",
  fromcode: "DMM",
}, {
  country: "tz",
  fromcode: "DAR",
}, {
  country: "tr",
  fromcode: "DNZ",
}, {
  country: "bd",
  fromcode: "DAC",
}, {
  country: "tr",
  fromcode: "DIY",
}, {
  country: "dj",
  fromcode: "JIB",
}, {
  country: "ua",
  fromcode: "DNK",
}, {
  country: "qa",
  fromcode: "DOH",
}, {
  country: "ua",
  fromcode: "DOK",
}, {
  country: "cm",
  fromcode: "DLA",
}, {
  country: "ae",
  fromcode: "DXB",
}, {
  country: "ie",
  fromcode: "DUB",
}, {
  country: "hr",
  fromcode: "DBV",
}, {
  country: "us",
  fromcode: "IAD",
}, {
  country: "za",
  fromcode: "DUR",
}, {
  country: "tj",
  fromcode: "DYU",
}, {
  country: "de",
  fromcode: "DUS",
}, {
  country: "gb",
  fromcode: "EDI",
}, {
  country: "tr",
  fromcode: "EDO",
}, {
  country: "ru",
  fromcode: "SVX",
}, {
  country: "tr",
  fromcode: "EZS",
}, {
  country: "ug",
  fromcode: "EBB",
}, {
  country: "tr",
  fromcode: "ERC",
}, {
  country: "tr",
  fromcode: "ERZ",
}, {
  country: "de",
  fromcode: "FRA",
}, {
  country: "de",
  fromcode: "FDH",
}, {
  country: "tr",
  fromcode: "GZT",
}, {
  country: "ch",
  fromcode: "GVA",
}, {
  country: "it",
  fromcode: "GOA",
}, {
  country: "se",
  fromcode: "GOT",
}, {
  country: "at",
  fromcode: "GRZ",
}, {
  country: "cn",
  fromcode: "CAN",
}, {
  country: "br",
  fromcode: "GRU",
}, {
  country: "mt",
  fromcode: "MLA",
}, {
  country: "az",
  fromcode: "KVD",
}, {
  country: "de",
  fromcode: "HAM",
}, {
  country: "vn",
  fromcode: "HAN",
}, {
  country: "de",
  fromcode: "HAJ",
}, {
  country: "cu",
  fromcode: "HAV",
}, {
  country: "vn",
  fromcode: "SGN",
}, {
  country: "hk",
  fromcode: "HKG",
}, {
  country: "us",
  fromcode: "IAH",
}, {
  country: "eg",
  fromcode: "HRG",
}, {
  country: "in",
  fromcode: "HYD",
}, {
  country: "ir",
  fromcode: "IFN",
}, {
  country: "pk",
  fromcode: "ISB",
}, {
  country: "tr",
  fromcode: "ISE",
}, {
  country: "tr",
  fromcode: "IST",
}, {
  country: "tr",
  fromcode: "SAW",
}, {
  country: "ua",
  fromcode: "IFO",
}, {
  country: "tr",
  fromcode: "ADB",
}, {
  country: "id",
  fromcode: "CGK",
}, {
  country: "sa",
  fromcode: "JED",
}, {
  country: "za",
  fromcode: "JNB",
}, {
  country: "af",
  fromcode: "KBL",
}, {
  country: "tr",
  fromcode: "KCM",
}, {
  country: "ng",
  fromcode: "KAN",
}, {
  country: "pk",
  fromcode: "KHI",
}, {
  country: "de",
  fromcode: "FKB",
}, {
  country: "tr",
  fromcode: "KSY",
}, {
  country: "tr",
  fromcode: "KFS",
}, {
  country: "np",
  fromcode: "KTM",
}, {
  country: "tr",
  fromcode: "ASR",
}, {
  country: "ru",
  fromcode: "KZN",
}, {
  country: "ir",
  fromcode: "KSH",
}, {
  country: "ua",
  fromcode: "HRK",
}, {
  country: "sd",
  fromcode: "KRT",
}, {
  country: "ua",
  fromcode: "KHE",
}, {
  country: "tj",
  fromcode: "LBD",
}, {
  country: "ua",
  fromcode: "KBP",
}, {
  country: "rw",
  fromcode: "KGL",
}, {
  country: "tz",
  fromcode: "JRO",
}, {
  country: "cd",
  fromcode: "FIH",
}, {
  country: "tr",
  fromcode: "KCO",
}, {
  country: "tr",
  fromcode: "KYA",
}, {
  country: "sk",
  fromcode: "KSC",
}, {
  country: "my",
  fromcode: "KUL",
}, {
  country: "kw",
  fromcode: "KWI",
}, {
  country: "ng",
  fromcode: "LOS",
}, {
  country: "pk",
  fromcode: "LHE",
}, {
  country: "cy",
  fromcode: "ECN",
}, {
  country: "de",
  fromcode: "LEJ",
}, {
  country: "ga",
  fromcode: "LBV",
}, {
  country: "pt",
  fromcode: "LIS",
}, {
  country: "si",
  fromcode: "LJU",
}, {
  country: "gb",
  fromcode: "LTN",
}, {
  country: "gb",
  fromcode: "STN",
}, {
  country: "gb",
  fromcode: "LHR",
}, {
  country: "gb",
  fromcode: "LGW",
}, {
  country: "us",
  fromcode: "LAX",
}, {
  country: "lu",
  fromcode: "LUX",
}, {
  country: "ua",
  fromcode: "LWO",
}, {
  country: "fr",
  fromcode: "LYS",
}, {
  country: "nl",
  fromcode: "MST",
}, {
  country: "sa",
  fromcode: "MED",
}, {
  country: "es",
  fromcode: "MAD",
}, {
  country: "sc",
  fromcode: "SEZ",
}, {
  country: "ve",
  fromcode: "CCS",
}, {
  country: "es",
  fromcode: "AGP",
}, {
  country: "tr",
  fromcode: "MLX",
}, {
  country: "mv",
  fromcode: "MLE",
}, {
  country: "bh",
  fromcode: "BAH",
}, {
  country: "gb",
  fromcode: "MAN",
}, {
  country: "ph",
  fromcode: "MNL",
}, {
  country: "mz",
  fromcode: "MPM",
}, {
  country: "tr",
  fromcode: "MQM",
}, {
  country: "fr",
  fromcode: "MRS",
}, {
  country: "ir",
  fromcode: "MHD",
}, {
  country: "af",
  fromcode: "MZR",
}, {
  country: "tr",
  fromcode: "MZH",
}, {
  country: "us",
  fromcode: "MIA",
}, {
  country: "it",
  fromcode: "MXP",
}, {
  country: "by",
  fromcode: "MSQ",
}, {
  country: "ly",
  fromcode: "MRA",
}, {
  country: "ly",
  fromcode: "MJI",
}, {
  country: "so",
  fromcode: "MGQ",
}, {
  country: "ke",
  fromcode: "MBA",
}, {
  country: "ca",
  fromcode: "YUL",
}, {
  country: "ru",
  fromcode: "DME",
}, {
  country: "ru",
  fromcode: "VKO",
}, {
  country: "ru",
  fromcode: "SVO",
}, {
  country: "iq",
  fromcode: "OSM",
}, {
  country: "in",
  fromcode: "BOM",
}, {
  country: "de",
  fromcode: "MUC",
}, {
  country: "tr",
  fromcode: "MSR",
}, {
  country: "om",
  fromcode: "MCT",
}, {
  country: "ke",
  fromcode: "NBO",
}, {
  country: "az",
  fromcode: "NAJ",
}, {
  country: "it",
  fromcode: "NAP",
}, {
  country: "td",
  fromcode: "NDJ",
}, {
  country: "tr",
  fromcode: "NAV",
}, {
  country: "in",
  fromcode: "DEL",
}, {
  country: "us",
  fromcode: "JFK",
}, {
  country: "us",
  fromcode: "EWR",
}, {
  country: "ne",
  fromcode: "NIM",
}, {
  country: "fr",
  fromcode: "NCE",
}, {
  country: "cy",
  fromcode: "NIC",
}, {
  country: "cz",
  fromcode: "INI",
}, {
  country: "mr",
  fromcode: "NKC",
}, {
  country: "ru",
  fromcode: "OVB",
}, {
  country: "de",
  fromcode: "NUE",
}, {
  country: "ua",
  fromcode: "ODS",
}, {
  country: "dz",
  fromcode: "ORN",
}, {
  country: "jp",
  fromcode: "KIX",
}, {
  country: "kg",
  fromcode: "OSS",
}, {
  country: "no",
  fromcode: "OSL",
}, {
  country: "bf",
  fromcode: "OUA",
}, {
  country: "pa",
  fromcode: "PTY",
}, {
  country: "fr",
  fromcode: "ORY",
}, {
  country: "fr",
  fromcode: "CDG",
}, {
  country: "kh",
  fromcode: "PNH",
}, {
  country: "th",
  fromcode: "HKT",
}, {
  country: "it",
  fromcode: "PSA",
}, {
  country: "mu",
  fromcode: "MRU",
}, {
  country: "yu",
  fromcode: "TGD",
}, {
  country: "pt",
  fromcode: "OPO",
}, {
  country: "cz",
  fromcode: "PRG",
}, {
  country: "lv",
  fromcode: "RIX",
}, {
  country: "sa",
  fromcode: "RUH",
}, {
  country: "it",
  fromcode: "FCO",
}, {
  country: "ru",
  fromcode: "ROV",
}, {
  country: "nl",
  fromcode: "RTM",
}, {
  country: "at",
  fromcode: "SZG",
}, {
  country: "ru",
  fromcode: "KUF",
}, {
  country: "tr",
  fromcode: "SZF",
}, {
  country: "us",
  fromcode: "SFO",
}, {
  country: "ye",
  fromcode: "SAH",
}, {
  country: "tr",
  fromcode: "SFQ",
}, {
  country: "ba",
  fromcode: "SJJ",
}, {
  country: "ly",
  fromcode: "SEB",
}, {
  country: "kr",
  fromcode: "ICN",
}, {
  country: "cn",
  fromcode: "PVG",
}, {
  country: "ie",
  fromcode: "SNN",
}, {
  country: "eg",
  fromcode: "SSH",
}, {
  country: "ir",
  fromcode: "SYZ",
}, {
  country: "sg",
  fromcode: "SIN",
}, {
  country: "tr",
  fromcode: "VAS",
}, {
  country: "mk",
  fromcode: "SKP",
}, {
  country: "bg",
  fromcode: "SOF",
}, {
  country: "ru",
  fromcode: "LED",
}, {
  country: "ru",
  fromcode: "STW",
}, {
  country: "se",
  fromcode: "ARN",
}, {
  country: "fr",
  fromcode: "SXB",
}, {
  country: "de",
  fromcode: "STR",
}, {
  country: "ir",
  fromcode: "TBZ",
}, {
  country: "sa",
  fromcode: "TIF",
}, {
  country: "tw",
  fromcode: "TPE",
}, {
  country: "ee",
  fromcode: "TLL",
}, {
  country: "uz",
  fromcode: "TAS",
}, {
  country: "ge",
  fromcode: "TBS",
}, {
  country: "tr",
  fromcode: "TEQ",
}, {
  country: "il",
  fromcode: "TLV",
}, {
  country: "gr",
  fromcode: "SKG",
}, {
  country: "al",
  fromcode: "TIA",
}, {
  country: "dz",
  fromcode: "TLM",
}, {
  country: "tr",
  fromcode: "TJK",
}, {
  country: "jp",
  fromcode: "NRT",
}, {
  country: "ca",
  fromcode: "YYZ",
}, {
  country: "fr",
  fromcode: "TLS",
}, {
  country: "tr",
  fromcode: "TZX",
}, {
  country: "ua",
  fromcode: "SIP",
}, {
  country: "ly",
  fromcode: "TIP",
}, {
  country: "tn",
  fromcode: "TUN",
}, {
  country: "it",
  fromcode: "TRN",
}, {
  country: "ba",
  fromcode: "TZL",
}, {
  country: "ru",
  fromcode: "UFA",
}, {
  country: "mn",
  fromcode: "ULN",
}, {
  country: "tr",
  fromcode: "USQ",
}, {
  country: "es",
  fromcode: "VLC",
}, {
  country: "tr",
  fromcode: "VAN",
}, {
  country: "fi",
  fromcode: "HEL",
}, {
  country: "bg",
  fromcode: "VAR",
}, {
  country: "it",
  fromcode: "VCE",
}, {
  country: "at",
  fromcode: "VIE",
}, {
  country: "lt",
  fromcode: "VNO",
}, {
  country: "ru",
  fromcode: "VOZ",
}, {
  country: "pl",
  fromcode: "WAW",
}, {
  country: "sa",
  fromcode: "YNB",
}, {
  country: "cm",
  fromcode: "NSI",
}, {
  country: "hr",
  fromcode: "ZAG",
}, {
  country: "tz",
  fromcode: "ZNZ",
}, {
  country: "ua",
  fromcode: "OZH",
}, {
  country: "ch",
  fromcode: "ZRH",
}];
