import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/service/api.service';
import {ApiFunctionName} from '../../shared/enum/api-function-name.enum';
import {ApiData} from '../../shared/model/api-data.model';
import {getAddressErrorMessage, getPasswordErrorMessage, Loading} from '../../utils';
import {AppStorage, StorageKey} from '../../storage';
import * as _ from 'lodash';
import {LanguageService} from '../../shared/service/language.service';
import * as moment from 'moment';
import {ApiOrder} from '../../shared/model/api.order.model';
import {ApiAccount} from '../../shared/model/api.account.model';
import {OrderStatus} from '../../shared/enum/order-status.enum';
import {PaymentType} from '../../shared/enum/payment-type.enum';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Bootbox} from '../../bootbox';
import {ApiDataService} from '../../shared/service/api-data.service';
import {ApiOnlinePayment} from 'src/app/shared/model/api.online-payment.model';
import {CartService} from 'src/app/shared/service/cart.service';
import {EnvironmentService} from '../../shared/service/environment.service';

@Component({
  selector: 'profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnInit {

  apiData: ApiData;

  profileName: string = '';
  profilePhone: string = '';
  profilePassword: string = '';
  profileCity: string = '-1';
  profileDistrict: string = '-1';
  profilePostcode: string = '';
  profileAddress: string = '';
  profileStreet: string = '';

  currency: string;

  selectedTab: string;

  changePassword: string;
  changePasswordConfirm: string;

  postCode: string;
  checkPostCode: any;
  country: string;

  _cities: any;
  _districts: any;
  _street: any;
  // noinspection JSMismatchedCollectionQueryUpdate
  _orders: [ApiOrder];

  // noinspection JSUnusedLocalSymbols
  OrderStatus = OrderStatus;

  // noinspection JSUnusedLocalSymbols
  PaymentType = PaymentType;

  isPaykukiPaymentNavigation: boolean = false;
  localPaymentID = null;

  constructor(private apiService: ApiService,
              private cartService: CartService,
              private apiDataService: ApiDataService,
              private languageService: LanguageService,
              private titleService: Title,
              public environmentService: EnvironmentService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    // Check is paykukiPayment navigation
    if (AppStorage.getItem('paykukiPayment')) {
      this.isPaykukiPaymentNavigation = true;
      this.localPaymentID = localStorage.getItem('PaymentID');
      AppStorage.removeItem('paykukiPayment');
      AppStorage.removeItem('PaymentID');
    }
    this.apiService.apiData(ApiFunctionName.GetMenu).subscribe(apiData => {
      this.apiData = apiData;
      this.country = this.apiData.Country;

      // If paykuki payment navigation open waitin pop-up
      if (this.isPaykukiPaymentNavigation) {
        Loading.hide();
        const target = 'my-orders';

        // Tab Index
        let tabIndex = 2;

        // Hide all panes
        $('.pane').addClass('uk-hidden');

        // Show target pane only
        $(`.pane#${target}`).removeClass('uk-hidden');

        //
        $('.nav-link').removeClass('active-button');

        // Highlight clicked button
        $('my-orders').addClass('active-button');

        this.onSelectedTabChanged(tabIndex);

        // @ts-ignore
        $('#paymentWatingPopup').modal('show');
      }

      const translations = this.languageService.translations;
      this.titleService.setTitle(`${this.apiData.Title} | ${translations['profile_details.title']}`);
      this.currency = this.apiDataService.getCurrency();

      this.initAccountInfoPage();
    });

    $('.nav-link').on('click', (e) => {
      // Get target ID
      const target = $(e.target).attr('data-target');

      // Tab Index
      let tabIndex = parseInt($(e.target).attr('data-tab-index'));

      if (typeof target === 'undefined') {
        this.onSelectedTabChanged(tabIndex);
        return;
      }

      // Hide all panes
      $('.pane').addClass('uk-hidden');

      // Show target pane only
      $(`.pane#${target}`).removeClass('uk-hidden');

      //
      $('.nav-link').removeClass('active-button');

      // Highlight clicked button
      $(e.target).addClass('active-button');

      this.onSelectedTabChanged(tabIndex);
    });

    $('.modal').on('shown.bs.modal', function() {
      $(this).before($('.modal-backdrop'));
    });
  }

  onSelectedCityChanged() {
    this.apiService.getDistricts(this.apiData.Settings.Locale, this.profileCity)
      .then(data => {
        this.profileDistrict = '-1';
        this._districts = data;
      });
  }

  onClickPostCodeButton() {
    const translations = this.languageService.translations;
    Loading.show().then(() => {
      this.postCode = this.profilePostcode;
      if (this.postCode !== '') {

        this.apiService.getAdress('GB', this.postCode).then(data => {
          this._street = data;

          if (this._street.length <= 0) {
            Bootbox.alert(translations['signup.postcode_validation_error_c']);
            return;
          } else {
            this.profileCity = this._street[0].Name;
            this.profileDistrict = this._street[0].District;
          }
        });
      }
    })
      .finally(() => Loading.hide());
  }

  onSelectedStreetChanged(value) {
    this.profileStreet = value;
  }

  onClickProfileSaveChanges() {
    const translations = this.languageService.translations;
    const user = AppStorage.getItem(StorageKey.USER) as ApiAccount;

    //region Validation
    if (_.isEmpty(this.profileName)) {
      Bootbox.alert(translations['general.name_error_c']);
      return;
    }

    if (this.country === 'GBR') {
      if (_.isEmpty(this.profileStreet)) {
        Bootbox.alert(translations['general.street_error_c']);
        return;
      }
    }

    const addressBundle = {
      telephone: this.profilePhone,
      address: this.profileAddress,
      city: this.profileCity,
      district: this.profileDistrict,
      street: this.profileStreet,
      postcode: this.profilePostcode
    };

    const addressError = getAddressErrorMessage(
      addressBundle,
      translations
    );


    if (addressError != null) {
      Bootbox.alert(addressError);
      return;
    }
    //endregion

    const account = {
      AccountID: user.AccountID,
      CustomerID: ApiService.customerId,
      Name: this.profileName,
      Cellphone: this.profilePhone,
      Telephone: this.profilePhone,
      Phone: this.profilePhone,
      ImageUrl: null,
      CityTown: this.profileCity,
      District: this.profileDistrict,
      Street: this.profileStreet,
      Address: this.profileAddress,
      UserName: this.profilePhone,
      Password: null,
      PostCode: this.profilePostcode
    };

    Loading.show()
      .then(() => {
        if (this.country !== 'GBR' && this.environmentService.environment.Locale === 'SA' || this.environmentService.environment.Locale === 'DE' || this.environmentService.environment.Locale === 'GB' || this.environmentService.environment.Locale === 'TR' ) {
          return this.apiService.checkPostcode(this.apiData.Settings.Locale, this.profileCity, this.profileDistrict, this.profilePostcode);
        }
      })
      .then(data => {
        if (data === 0) {
          throw 0;
        }
      })
      .then(() => this.apiService.putAccount(account))
      .then((data) => {
        if (!data.hasOwnProperty('ID')) {
          return;
        }

        return this.apiService.getAccount(data['ID']);
      })
      .then((data) => {
        AppStorage.setItem(StorageKey.USER, data);

        Bootbox.alert(translations['profile_details.account_info.success_profile_update']);
      })
      .catch((error) => {
        if (error === 0) {
          Bootbox.alert(translations['profile_details.account_info.postcode_validation_error_c']);
          return;
        }

        Bootbox.alert(translations['profile_details.account_info.profile_update_error_c']);
      })
      .finally(() => {
        Loading.hide();
      });
  }

  onClickChangePasswordButton() {
    const translations = this.languageService.translations;
    const error = getPasswordErrorMessage(this.changePassword, translations);

    if (error != null) {
      Bootbox.alert(error);
      return;
    }

    if (this.changePassword !== this.changePasswordConfirm) {
      Bootbox.alert(translations['profile_details.change_password.passwords_not_match']);
      return;
    }

    const account = AppStorage.getItem(StorageKey.USER) as ApiAccount;
    account.Password = this.changePassword;

    Loading.show()
      .then(() => this.apiService.putAccount(account))
      .then(() => {
        this.changePassword = '';
        this.changePasswordConfirm = '';

        Bootbox.alert(translations['profile_details.change_password.password_updated']);
      })
      .finally(() => {
        Loading.hide();
      });
  }

  onSelectedTabChanged(idx: number) {
    switch (idx) {
      case 1: {
        this.initAccountInfoPage();
        break;
      }
      case 2: {
        this.initOrderPage();
        break;
      }
      case 3: {
        break;
      }
      case 4: {
        break;
      }
      case 5: {
        AppStorage.removeItem(StorageKey.CART);
        AppStorage.removeItem(StorageKey.USER);

        window.location.href = '/';
        break;
      }
    }
  }

  initAccountInfoPage() {
    const user = AppStorage.getItem(StorageKey.USER) as ApiAccount;

    if (user == null) {
      return;
    }

    Loading.show()
      .then(() => this.apiService.getCities(this.apiData.Settings.Locale))
      .then(cities => {
        this._cities = cities;
      })
      .then(() => this.apiService.getAccount(user.AccountID))
      .then((account: ApiAccount) => {
        this.profileName = account.Name;
        this.profilePhone = account.Cellphone;
        this.profilePassword = account.Address;
        this.profilePostcode = account.PostCode;
        this.profileCity = account.CityTown;
        this.profileDistrict = account.District;
        this.profileAddress = account.Address;

        this.profileCity = this._cities.find(city => {
          return city.Name === this.profileCity;
        });

        if (typeof this.profileCity !== 'undefined') {
          this.profileCity = this.profileCity['Name'];
          return;
        }

        this.profileCity = '-1';
      })
      .then(() => this.apiService.getDistricts(this.apiData.Settings.Locale, this.profileCity))
      .then((data: []) => {
        if (data.length === 0) {
          this.profileDistrict = '-1';
          return;
        }

        this._districts = data;

        this.profileDistrict = this._districts.find(district => {
          return district.Name === this.profileDistrict;
        });

        if (typeof this.profileDistrict !== 'undefined') {
          this.profileDistrict = this.profileDistrict['Name'];
          return;
        }

        this.profileDistrict = '-1';
      })
      .finally(() => {
        Loading.hide();
      });
  }

  onClickPaykukiPayButton(paykukiOrder: ApiOrder) {
    console.log('processPaykukiPayment');
    // Create a new online payment
    const onlinePayment = new ApiOnlinePayment();
    onlinePayment.Type = this.apiData.Settings.OnlinePaymentMode;

    onlinePayment.CardHolderName = '';
    onlinePayment.CardNumber = '';
    onlinePayment.CardCVC = '';

    const expire = '';
    onlinePayment.CardMonth = '';
    onlinePayment.CardYear = '';

    // Set order details
    onlinePayment.OnlineOrderID = paykukiOrder.OnlineOrderID;
    onlinePayment.Price = paykukiOrder.TotalPrice;

    // Set account ID
    onlinePayment.AccountID = paykukiOrder.AccountID;

    // Set customer ID
    onlinePayment.CustomerID = paykukiOrder.CustomerID;

    Loading.show()
      .then(() => this.apiService.postOnlinePayment(onlinePayment))
      .then((data: ApiResponseObject) => {
        console.log(data);

        if (!data.hasOwnProperty('Result')) {
          return;
        }

        data.Result = JSON.parse(data.Result);
        const checkoutUrl = data.Result['CheckoutUrl'];
        window.open(checkoutUrl, '_blank').focus();

        // Clear cart
        this.cartService.clear();
        Loading.hide();
        // @ts-ignore
        $('#paymentWatingPopup').modal('show');
      })
      .catch(() => {
        // @ts-ignore
        $('#paymentFailedPopup').modal('show');
      })
      .finally(() => {
        Loading.hide();
      });
  }

  paymentWatingPopupClickButton() {
    if (this.localPaymentID !== null) {
      this.apiService.checkStripePayment(this.localPaymentID).then()
        .finally(() => {
          this.localPaymentID = null;
          this.initOrderPage();
        });
    }
    else {
      this.initOrderPage();
    }

  }

  initOrderPage() {
    const user = AppStorage.getItem(StorageKey.USER) as ApiAccount;

    if (user == null) {
      return;
    }

    Loading.show()
      .then(() => this.apiService.getAccountOrders(user.AccountID))
      .then((orders: [ApiOrder]) => {
        this._orders = orders;

        for (let key in orders) {
          let order = orders[key];

          order.OrderTimeFormatted = moment(order.OrderTime).format('L LTS');

          for (let orderItemsKey in order.OrderItems) {
            let orderItem = order.OrderItems[orderItemsKey];

            orderItem.OrderItemFeaturesString = orderItem.OrderItemFeatures
              .map(x => x.Name).join(', ');
          }
        }
      })
      .finally(() => {
        Loading.hide();
      });
  }
}
